/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {FlattenedConfigProxy} from '@exc/shared';
import type {RuntimeModulesSync} from './runtimeModels';
import {SettingsLevel} from '@adobe/exc-app/settings/SettingsLevel';

export interface ApiRecipe {
  /**
   * Static elements present on the API module (ie. constants).
   * These should be avoided with new APIs and added to @adobe/exc-app instead.
   * Used for legacy purposes only.
   */
  base?: Record<string, any>,
  /**
   * Methods exposed by the API.
   */
  methods: string[],
  /**
   * Properties exposed by the API.
   * This will be mirrored from ConfigProxy.
   */
  properties?: (keyof FlattenedConfigProxy)[],
  /**
   * API name.
   */
  name: keyof RuntimeModulesSync,
}

// Agreements API recipe
const agreementsRecipe: ApiRecipe = {
  methods: ['showAgreement', 'getResponse', 'clearResponse'],
  name: 'agreements'
};

// AI API recipe
const aiRecipe: ApiRecipe = {
  methods: ['closeChat', 'emit', 'off', 'on', 'onContextRequest', 'openChat', 'sendMessage', 'showAssistant', 'toggleButtonActive', 'toggleButtonVisible'],
  name: 'ai',
  properties: ['ai/context']
};

// App API recipe
const appRecipe: ApiRecipe = {
  methods: ['get'],
  name: 'app'
};

// Cache API recipe
const cacheRecipe: ApiRecipe = {
  methods: ['delete', 'get', 'set'],
  name: 'cache'
};

// Modules API recipe
const modulesRecipe: ApiRecipe = {
  methods: ['get', 'getSrcDoc'],
  name: 'modules'
};

// Component API recipe
const componentRecipe: ApiRecipe = {
  methods: ['close', 'isComponent', 'open'],
  name: 'component'
};

// Consent API recipe
const consentRecipe: ApiRecipe = {
  methods: ['getPermissions', 'updatePermissions'],
  name: 'consent'
};

// Feature Flags API
const featureFlagsRecipe: ApiRecipe = {
  methods: ['get'],
  name: 'featureFlags'
};

// Network Prefetch API recipe
const networkPrefetchRecipe: ApiRecipe = {
  methods: ['prefetch'],
  name: 'networkPrefetch'
};

// Permissions API recipe
const permissionsRecipe: ApiRecipe = {
  methods: ['get'],
  name: 'permissions'
};

// Pulse API recipe
const pulseRecipe: ApiRecipe = {
  methods: ['send', 'setCount', 'setButton'],
  name: 'pulse'
};

// Session API recipe
const sessionRecipe: ApiRecipe = {
  methods: ['get', 'invalidate', 'set'],
  name: 'session'
};

// Settings API recipe
const settingsRecipe: ApiRecipe = {
  base: {
    SETTINGS_LEVEL: SettingsLevel
  },
  methods: ['get', 'set'],
  name: 'settings'
};

// Shell API recipe
const shellRecipe: ApiRecipe = {
  methods: ['emit', 'get', 'getExtendedShellInfo', 'off', 'on'],
  name: 'shell'
};

// User API recipe
const userRecipe: ApiRecipe = {
  methods: ['authExpired', 'emit', 'generateSubOrg', 'get', 'getAEMInstances', 'getFulfillableItems', 'off', 'on'],
  name: 'user',
  properties: ['logoutUrl', 'showLanguagePicker', 'showRolesPicker']
};

// Sidenav API recipe
const sidenavRecipe: ApiRecipe = {
  methods: ['emit', 'get', 'off', 'on', 'onNavItemClick', 'useLeftNavButton'],
  name: 'sidenav',
  properties: ['sidenav/visible', 'sidenav/collapsed', 'sidenav/config']
};

export {
  agreementsRecipe,
  aiRecipe,
  appRecipe,
  cacheRecipe,
  componentRecipe,
  consentRecipe,
  featureFlagsRecipe,
  permissionsRecipe,
  modulesRecipe,
  networkPrefetchRecipe,
  pulseRecipe,
  sessionRecipe,
  settingsRecipe,
  shellRecipe,
  sidenavRecipe,
  userRecipe
};
