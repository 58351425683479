/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import appControllerService from './AppControllerService';
import type {ConfigureNetworkFn} from '../network';
import {Events, InternalContainer} from '@exc/shared';
import type {MetricsApiContainer} from '../metrics';
import type {Poller, PollerHandle, PollingConfiguration, UserActivityEmitter, UserActivityMonitor} from '@adobe/exc-app/internal';
import type {PollingService} from '../polling';
import type {RuntimeEngine, RuntimeMessenger} from '../models/runtimeModels';
import {sendAndReceive} from '../util';

export default (
  configureNetwork: ConfigureNetworkFn,
  {metricsApi, metricsInternal}: MetricsApiContainer,
  window: Window,
  engine?: RuntimeEngine,
  messenger?: RuntimeMessenger
): InternalContainer => {
  let activityMonitor: UserActivityMonitor;
  let pollingService: PollingService;

  const {registerApp, verifyMessage, unregisterApp} = appControllerService;
  const initPolling = async (): Promise<void> => {
    if (!activityMonitor) {
      const {createActivityMonitor, createPollingService} = await import('../polling');
      activityMonitor = createActivityMonitor(window);
      pollingService = createPollingService(activityMonitor, metricsApi, window);
    }
  };

  const addPoller = async (poller: Poller): Promise<PollerHandle> => {
    await initPolling();
    return pollingService.addPoller(poller);
  };

  const configurePolling = async (config: PollingConfiguration): Promise<void> => {
    await initPolling();
    pollingService.configure(config);
    activityMonitor.configure(config);
  };

  const removePoller = async (handle: PollerHandle): Promise<void> => {
    await initPolling();
    pollingService.removePoller(handle);
  };

  const registerActivityEmitter = async (emitter: UserActivityEmitter): Promise<void> => {
    await initPolling();
    activityMonitor.registerActivityEmitter(emitter);
  };

  const getActivityMonitor = async (): Promise<UserActivityMonitor> => {
    await initPolling();
    return activityMonitor;
  };

  const waitForGainsight = async (): Promise<void> => {
    if (!engine || !messenger) {
      throw new Error('API only runs in an iframe');
    }
    return sendAndReceive<undefined, void>(Events.GAINSIGHT_INIT, messenger).then(() => engine.configurePromise);
  };

  return {
    addPoller,
    configureNetwork,
    configurePolling,
    getActivityMonitor,
    preloadEngine: () => import('../runtimeEngine'),
    registerActivityEmitter,
    registerApp,
    removePoller,
    unregisterApp,
    verifyMessage,
    waitForConfigure: () => engine?.configurePromise,
    waitForGainsight,
    ...metricsInternal
  };
};
