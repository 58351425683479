/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ConfigProxyManager} from '../config';
import {Events} from '@exc/shared';
import type {Nps} from '@adobe/exc-app/nps';
import type {RuntimeMessenger} from '../models/runtimeModels';

export default (messenger: RuntimeMessenger, configManager: ConfigProxyManager) => {
  // This is now deprecated in favor of the Total User Experience (TUX) survey
  // delivered by Unified Shell.
  // Remove this once all solutions have transitioned to TUX.
  const npsInstance = {
    triggerNps: () => messenger.send(Events.DEPRECATION_WARNING, 'nps')
  } as Nps;

  configManager.setupConfig(
    npsInstance,
    ['config'],
    () => messenger.send(Events.DEPRECATION_WARNING, 'nps')
  );

  return npsInstance;
};
