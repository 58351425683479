/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AgreementsApi} from './agreements';
import {
  agreementsRecipe,
  aiRecipe,
  ApiRecipe,
  appRecipe,
  cacheRecipe,
  componentRecipe,
  consentRecipe,
  featureFlagsRecipe,
  modulesRecipe,
  networkPrefetchRecipe,
  permissionsRecipe,
  pulseRecipe,
  sessionRecipe,
  settingsRecipe,
  shellRecipe,
  sidenavRecipe,
  userRecipe
} from './models/apiModels';
import type {AIApi} from './ai';
import type {AppApi} from './api/AppApi';
import type BaseApi from './api/BaseApi';
import type {CacheApi} from './cache';
import type {ComponentApi} from './component';
import type {ConfigProxyManager} from './config';
import type {
  ConfigureListener,
  RuntimeEngine,
  RuntimeMessenger,
  RuntimeModules, RuntimeModulesSync,
  RuntimeObject
} from './models/runtimeModels';
import type {ConsentApi} from './consent';
import type {FeatureFlagsApi} from './featureflags';
import type {InternalApi} from '@adobe/exc-app/internal';
import type {MetricsApi} from '@adobe/exc-app/metrics';
import type {ModulesApi} from './api/ModulesApi';
import type {NetworkPrefetchApi} from './network/prefetch';
import type {PerformanceRecord} from '@adobe/exc-app/page';
import type {PermissionsApi} from './permissions';
import type {PulseApi} from './pulse';
import type {Runtime, RuntimeConfiguration} from '@adobe/exc-app';
import type {RuntimeEngineSync} from './runtimeEngine';
import type {SessionApi} from './session';
import type {SettingsApi} from './settings';
import type {ShellApi} from './shell';
import type {SidenavApi} from './sidenav';
import type {UserApi} from './user';
import type {UserKeyEvent, UserMouseEvent} from '@exc/shared';

type DeferredFn = () => Promise<RuntimeEngineSync>;

const getDeferredEngine = (
  messenger: RuntimeMessenger,
  configManager: ConfigProxyManager,
  listeners: ConfigureListener[],
  metricsApi: MetricsApi,
  window: Window & typeof globalThis
): DeferredFn => {
  let deferredImport: Promise<RuntimeEngineSync>;

  return (): Promise<RuntimeEngineSync> => {
    if (!deferredImport) {
      deferredImport = import('./runtimeEngine')
        .then(({default: createEngine}) => createEngine(messenger, configManager, listeners, metricsApi, window));
    }
    return deferredImport;
  };
};

export const done = (timestamp: number, messenger: RuntimeMessenger, metricsApi: MetricsApi, win: Window) : Promise<PerformanceRecord> =>
  import('./runtimeEngine').then(({done: doneSync}) => doneSync(timestamp, messenger, metricsApi, win));

const passForward = (
  apiName: keyof RuntimeModulesSync,
  method: string,
  deferred: DeferredFn
) => (...args: any) => deferred().then(({modules}) => modules[apiName])
  .then(module => ((module as any)[method])(...args));

const composeApi = <T extends BaseApi>(
  {base = {}, methods, properties = [], name}: ApiRecipe,
  config: ConfigProxyManager, deferred: DeferredFn): () => T => {
  // Set api base structure
  const api: Record<string, any> = {...base};
  // Add methods
  methods.forEach(method => api[method] = passForward(name, method, deferred));
  // Add properties
  properties.forEach(property => {
    const key = property.includes('/') ? property.split('/')[1] : property;
    Object.defineProperty(
      api,
      key,
      {
        get: () => config.get(property),
        set: (value?: any) => config.set({[property]: value})
      }
    );
  });
  return () => api as T;
};

const createModules = (configManager: ConfigProxyManager, deferred: DeferredFn): RuntimeModules => ({
  agreements: composeApi<AgreementsApi>(agreementsRecipe, configManager, deferred),
  ai: composeApi<AIApi>(aiRecipe, configManager, deferred),
  appApi: composeApi<AppApi>(appRecipe, configManager, deferred),
  cache: composeApi<CacheApi>(cacheRecipe, configManager, deferred),
  component: composeApi<ComponentApi>(componentRecipe, configManager, deferred),
  consent: composeApi<ConsentApi>(consentRecipe, configManager, deferred),
  featureFlags: composeApi<FeatureFlagsApi>(featureFlagsRecipe, configManager, deferred),
  modules: composeApi<ModulesApi>(modulesRecipe, configManager, deferred),
  networkPrefetch: composeApi<NetworkPrefetchApi>(networkPrefetchRecipe, configManager, deferred),
  permissions: composeApi<PermissionsApi>(permissionsRecipe, configManager, deferred),
  pulse: composeApi<PulseApi>(pulseRecipe, configManager, deferred),
  session: composeApi<SessionApi>(sessionRecipe, configManager, deferred),
  settings: composeApi<SettingsApi>(settingsRecipe, configManager, deferred),
  shell: composeApi<ShellApi>(shellRecipe, configManager, deferred),
  sidenav: composeApi<SidenavApi>(sidenavRecipe, configManager, deferred),
  user: composeApi<UserApi>(userRecipe, configManager, deferred)
});

export default (
  messenger: RuntimeMessenger,
  configManager: ConfigProxyManager,
  listeners: ConfigureListener[],
  metricsApi: MetricsApi,
  window: Window & typeof globalThis
): RuntimeEngine => {
  const deferred = getDeferredEngine(messenger, configManager, listeners, metricsApi, window);
  const deferredMethod = (method: keyof RuntimeEngineSync) =>
    (...args: any) => deferred().then(engineModule => (engineModule as any)[method](...args));
  const modules = createModules(configManager, deferred);
  const app = (): Promise<AppApi> => deferred().then(({modules: modulesSync}) => modulesSync.app);
  const configurePromise = deferred().then(({configurePromise: promise}) => promise);
  return {
    app,
    configurePromise,
    modules,
    onConfigure: (runtime: RuntimeObject, config: RuntimeConfiguration, internal: InternalApi) =>
      deferredMethod('onConfigure')(runtime, config, internal),
    onPageChange: (runtime: RuntimeObject) => deferredMethod('onPageChange')(runtime),
    onShellUserInput: (e: UserKeyEvent | UserMouseEvent) => deferredMethod('onShellUserInput')(e),
    waitForInit: (runtimeInstance: {instance: Runtime|undefined}) => deferredMethod('waitForInit')(runtimeInstance)
  };
};
