/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  ApolloClientLegacyOptions,
  ApolloClientV3Classes,
  Configuration,
  CreateApolloClientOptions,
  NetworkApi,
  PrefetchOptions,
  PrefetchResponse
} from '@adobe/exc-app/network';
import createFetchApi from './fetch';
import createGraphQlApi from './graphql';
import {Events} from '@exc/shared';
import type {MetricsApi} from '@adobe/exc-app/metrics';
import type {RuntimeEngine, RuntimeMessenger} from '../models/runtimeModels';

export type ConfigureNetworkFn = (config: Configuration) => void;

export interface NetworkObject {
  network: NetworkApi;
  configureNetwork: ConfigureNetworkFn;
}

export default (metrics: MetricsApi, win: Window & typeof globalThis, engine?: RuntimeEngine, messenger?: RuntimeMessenger): NetworkObject => {
  const fetchApi = createFetchApi(metrics, win, messenger);
  const graphql = createGraphQlApi(fetchApi.fetch, metrics, win);

  const configureNetwork: ConfigureNetworkFn = config => {
    fetchApi.configure(config);
    graphql.configure(config);
  };

  return {
    configureNetwork,
    network: {
      createApolloClient: async <TCacheShape>(apolloClientProvided: ApolloClientV3Classes, options: CreateApolloClientOptions<TCacheShape>) =>
        (await import('./custom-apollo-client')).createCustomApolloClient(graphql.baseQuery, options, apolloClientProvided),
      fetch: fetchApi.fetch,
      getApolloClient: async (options?: ApolloClientLegacyOptions) => {
        messenger?.send(Events.DEPRECATION_WARNING, 'getApolloClient');
        return (await import('./custom-apollo-client')).getApolloClient(graphql.baseQuery, options);
      },
      getPrefetched: <T>(key: string, options?: PrefetchOptions): Promise<PrefetchResponse<T>> => {
        if (!engine) {
          throw new Error('API only runs in an iframe');
        }
        return engine.modules.networkPrefetch().prefetch<T>(key, options);
      },
      query: graphql.query
    }
  };
};
