/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AdobeMetricsWithRecents} from '../runtime';
import type {EventData, MetricsApi, PageData, Record, Timer, UserData} from '@exc/metrics';
import {Level} from '@adobe/exc-app/metrics/Level';
import {RecordType} from '@adobe/exc-app/metrics/RecordType';
import {runWithTimeout} from '@exc/shared';

export type FilterFn = (record: Record) => Promise<Record>;
export type OptOutFn = (optOut: boolean) => void;
export type WriteFn = (record: Record) => Promise<number>;

export const OMEGA_TIMEOUT_MS = 5000;

class MetricsTimer implements Timer {
  public epoch: number;
  public now: number;
  public readonly prefix: string;
  public readonly name: string;
  private readonly context: any;
  private readonly write: WriteFn;
  private readonly nowFn: () => number;

  public constructor(write: WriteFn, nowFn: () => number, name: string, context: any, prefix: string, ...args: any) {
    this.nowFn = nowFn;
    this.epoch = nowFn();
    this.now = nowFn();
    this.context = context;
    this.name = name;
    this.prefix = prefix;
    this.write = write;
    this.time('start', ...args);
  }

  public time(eventName: string | {event: string}, ...args: any): number {
    let event: string;
    // calculate new event
    if (eventName && typeof eventName === 'object') {
      event = eventName.event;
    } else {
      event = (this.prefix || '') + (this.prefix && eventName ? '.' : '') + (eventName || '');
    }

    const _now: number = this.nowFn();
    const duration: number = _now - this.epoch;
    const record: Record = {
      data: args.length > 0 ? [...args] : undefined,
      dateNow: Date.now(),
      duration,
      epoch: this.epoch,
      event,
      level: Level.INFO,
      metricsState: {
        context: this.context,
        correlationId: 'uuid'
      },
      name: this.name,
      now: _now,
      prefix: this.prefix,
      recordType: RecordType.TIMER
    } as Record;
    this.write(record);
    return duration;
  }
}

export default class LazyMetrics implements MetricsApi {
  public context: any;
  public readonly name: string;
  private readonly write: WriteFn;
  private readonly getAdobeMetrics: () => AdobeMetricsWithRecents;
  private readonly verbose: boolean;
  private readonly waitForOmega: () => Promise<boolean>;
  private readonly nowFn: () => number;
  private omegaPromise?: Promise<boolean>;

  public constructor(write: WriteFn, win: Window, name: string, ...args: any) {
    const {location, performance: {now}} = win;
    this.verbose = location.search.includes('shell_verbose=true');
    this.nowFn = now.bind(win.performance);
    this.getAdobeMetrics = () => win.adobeMetrics;
    this.write = write;
    this.name = name;
    args.length && (this.context = args[0]);
    const waitForOmegaInit = async (): Promise<boolean> => {
      if (win._satellite) {
        return true;
      }
      return new Promise(resolve => win.addEventListener('init_omega', () => resolve(true)));
    };

    this.waitForOmega = () => {
      if (!this.omegaPromise) {
        this.omegaPromise = runWithTimeout(waitForOmegaInit(), OMEGA_TIMEOUT_MS, false, win);
      }
      return this.omegaPromise;
    };
  }

  public start(prefix: string, ...args: any): Timer {
    return new MetricsTimer(this.write, this.nowFn, this.name, this.context, prefix, ...args);
  }

  private _log(recordType: RecordType, level: Level, name: string, message: string | undefined, event: string | string[] | undefined,
    dateNow: number | undefined, args: any[]): Promise<number> {
    if (this.verbose && args?.length) {
      const levelOverride = args.find(data =>
        typeof data === 'object' && ['DEBUG', 'ERROR', 'WARN'].includes(data?.level) && Object.keys(data).length === 1);
      levelOverride && (level = levelOverride.level);
    }
    return this.write({
      data: args.length > 0 ? [...args] : undefined,
      dateNow: dateNow || Date.now(),
      event,
      level,
      message,
      name,
      recordType
    } as Record);
  }

  private _historyLog(event: string, args: any): Promise<number> {
    return this._log(RecordType.HISTORY, Level.INFO, this.name, undefined, event, undefined, args);
  }

  private _trackLog(type: string, args: any): Promise<number> {
    return this._log(RecordType.ANALYTICS, Level.INFO, this.name, undefined, type, undefined, args);
  }

  /**
   * Queue a record for transmission to remote storage.
   * @param {Record} record - The object to be stored.
   */
  public store<T extends Record>(record: T): void {
    record.name = this.name;
    this.write(record);
  }

  /**
   * Log an ERROR message to remote storage, with an optional
   * data payload; emulates console.error().
   * @function
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public error(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.ERROR, this.name, message, undefined, undefined, args);
  }

  /**
   * Log a WARN message to remote storage, with an optional data payload;
   * emulates console.warn().
   * @function
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public warn(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.WARN, this.name, message, undefined, undefined, args);
  }

  /**
   * Log an INFO message to remote storage, with an optional data payload;
   * emulates console.log().
   * @function
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public info(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.INFO, this.name, message, undefined, undefined, args);
  }

  /**
   * Log an INFO message to remote storage, with an optional data payload;
   * emulates console.info().
   * @function
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public log(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.INFO, this.name, message, undefined, undefined, args);
  }

  /**
   * Log a DEBUG message to remote storage, with an optional data payload;
   * emulates console.debug().
   * @function
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public debug(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.DEBUG, this.name, message, undefined, undefined, args);
  }

  /**
   * Log a TRACE message to remote storage, with an optional data payload;
   * emulates console.trace().
   * @param {string} message - The log message.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public trace(message: string, ...args: any): Promise<number> {
    return this._log(RecordType.LOG, Level.TRACE, this.name, message, undefined, undefined, args);
  }

  /**
   * Construct a named event and emit it to storage, along with an optional
   * data payload. Event level will default to INFO. To specify a different
   * level, add an level object to the args parameters in the form
   * {level: Level.DEBUG}.
   * @function
   * @param {string} event The event.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public event(event: string | string[], ...args: any): Promise<number> {
    return this._log(RecordType.EVENT, Level.INFO, this.name, undefined, event, undefined, args);
  }

  /**
   * Construct a named "Recent" event and emit it to storage. The payload is expected
   * to contain PII so downstream handling must a) keep the data bag intact, and
   * b) send the Recent record (with PII) only to Unified Recents. PII must be
   * removed before sending to ADX.
   * @function
   * @param {string} recent The event.
   * @param {any} args Optional arguments to be applied to the recorded metrics.
   * @returns {Promise} A promise that resolves to the number of metrics that
   * were queued for eventual flushing.
   */
  public recent(recent: string | string[], ...args: any): Promise<number> {
    const {onRecents} = this.getAdobeMetrics();
    onRecents && onRecents(recent, args);
    return this._log(RecordType.RECENT, Level.INFO, this.name, undefined, recent, undefined, args);
  }

  /**
   * The history object emulates window history APIs for recording history
   * metrics. When using push or replace you can specify the path and state
   * as separate arguments, or specify path as a string and state as an
   * object, or put everything into one location-like argument. In all cases,
   * the resulting history record will be a location-like argument of the
   * form {path, search, hash, state}.
   *
   * If path is supplied as a string it will be disassembled into a
   * location-like object and the state parameter will be added to that
   * object if the supplied state parameter was an object.
   *
   * The resulting location-like object will be stored in the data property of
   * the metric if the prop props argument is empty. If props is not empty,
   * then the constructed location-like object will be the first object in an
   * array on the metric data property, and the remainder of the array will
   * come from processing the props parameter through makeDataBag().
   */
  public history = {
    back: (...args: any): Promise<number> => this._historyLog('back', args),
    forward: (...args: any): Promise<number> => this._historyLog('forward', args),
    go: (n: number, ...args: any): Promise<number> => {
      args.unshift({n});
      return this._historyLog('go', args);
    },
    push: (path: any, state?: any, ...args: any): Promise<number> => {
      args.unshift({path, state});
      return this._historyLog('push', args);
    },
    replace: (path: any, state?: any, ...args: any): Promise<number> => {
      args.unshift({path, state});
      return this._historyLog('replace', args);
    }
  };

  /**
   * Adobe Analytics for Adobe - aka OMEGA. These APIs emulate
   * window._satellite.track() methods.
   *
   * Applications which self-manage the analytics javascript and handle
   * updating the window.digitalData object should call metrics.analytics.track()
   * immediately adjacent to calling window._satellite.track(), either before or
   * after.
   *
   * Applications which provide the analytics javascript url to Metrics Runtime
   * configuration can can call trackEvent(), trackPage() or trackUser() and
   * supply the corresponding data object as an argument. In this case, Metrics
   * will update the window digitalData object and take care of invoking
   * window._satellite.track as well as Metrics' track().
   */
  public analytics = {
    track: (type: 'event' | 'page' | 'user', ...args: any): Promise<number> => this.waitForOmega().then(() => this._trackLog(type, args)),
    trackEvent: (eventData: EventData, ...args: any): Promise<number> => {
      args.unshift(eventData);
      return this.waitForOmega().then(() => this._trackLog('Event', args));
    },
    trackPage: (pageData: PageData, ...args: any): Promise<number> => {
      args.unshift(pageData);
      return this.waitForOmega().then(() => this._trackLog('Page', args));
    },
    trackUser: (userData: UserData, ...args: any): Promise<number> => {
      args.unshift(userData);
      return this.waitForOmega().then(() => this._trackLog('User', args));
    }
  };

  /**
   * Location methods emit Location recordTypes and also triggers tracking of
   * a new page load. A new historyId will be generated, page state will
   * transition to active. Location methods should be await'ed just prior to the
   * corresponding window.location method to allow flush of any remaining
   * metrics from the current window to remote storage before the window
   * contents change.
   */
  public location = {
    /**
     * Record a location assign event.
     * @param {string} url A string for the window location path being assigned.
     * @param {any} props Any other properties will be assigned to the metric
     * according to makeDataBag();.
     * @returns {Promise} A promise that resolves to the number of metrics that
     * were flushed to remote storage.
     */
    assign: (url: string, ...props: any): Promise<number> => {
      props.unshift({url});
      return this._log(RecordType.LOCATION, Level.INFO, this.name, undefined, 'assign', undefined, props);
    },

    /**
     * Record a location reload event.
     * @param {boolean} force A boolean to indicate that the page was or will be
     * reloaded from the server.
     * @param {any} props Any other properties will be assigned to the metric
     * according to makeDataBag();.
     * @returns {Promise} A promise that resolves to the number of metrics that
     * were flushed to remote storage.
     */
    reload: (force: boolean, ...props: any): Promise<number> => {
      props.unshift({force});
      return this._log(RecordType.LOCATION, Level.INFO, this.name, undefined, 'reload', undefined, props);
    },

    /**
     * Record a location replace event.
     * @param {string} url A string for the window location path being assigned.
     * @param {any} props Any other properties will be assigned to the metric
     * according to makeDataBag();.
     * @returns {Promise} A promise that resolves to the number of metrics that
     * were flushed to remote storage.
     */
    replace: (url: string, ...props: any): Promise<number> => {
      props.unshift({url});
      return this._log(RecordType.LOCATION, Level.INFO, this.name, undefined, 'replace', undefined, props);
    }
  };
}
