/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Configuration, DefaultMetaData, FetchInit, FetchScope} from '@adobe/exc-app/network';
import InvalidStatusCodeError from './invalidStatusCodeError';
import {Level, Metrics, MetricsApi} from '@adobe/exc-app/metrics';
import type {RuntimeMessenger} from '../models/runtimeModels';
import type {Sandbox} from '@adobe/exc-app/user';
import {statusAwareFetch, StatusAwareFetchInit} from './fetchUtils';
import uuidv1 from 'uuid/v1';

export type ExtendedFetch = (
  fetchInput: RequestInfo,
  fetchInit?: FetchInit,
  ioEndpoint?: string,
  ioRegionSpecificMap?: Record<string, string>
) => Promise<Response>;

type StatusAwareFetchFn = (input: RequestInfo, init: StatusAwareFetchInit) => Promise<Response>;

interface FetchExecParams {
  logger: Metrics;
  fetchConfig: FetchConfig;
  win: Window,
  saFetch: StatusAwareFetchFn;
  input: RequestInfo;
  init?: FetchInit;
  messenger?: RuntimeMessenger;
  ioEndpoint?: string;
  ioRegionSpecificMap?: Record<string, string>;
}

export interface FetchConfig {
  apiKey: string;
  appId: string;
  imsOrg: string;
  imsToken: string;
  sandbox: Sandbox;
}

interface FetchParams {
  imsOrgId?: string;
  sandboxName?: string;
  sandboxType?: string;
  isDefaultSandbox?: string;
}

interface FetchInitNormalizedHeaders extends FetchInit {
  headers: Headers
}

function setAuth(init: FetchInitNormalizedHeaders, metadata: DefaultMetaData) {
  init.headers.set('Authorization', `Bearer ${metadata.token}`);
  // imsClientId is the backcompat property that allowed users to override the
  // api key header. This needs to stay as the prioritized property to allow
  // existing implementations to work correct. apiKey is the new property, which
  // is also set by default from Unified Shell, but can be overridden by users
  // also in the same way.
  const apiKey = metadata.imsClientId || metadata.apiKey;
  apiKey && init.headers.set('x-api-key', apiKey);
}

function setImsOrg(init: FetchInitNormalizedHeaders, metadata: DefaultMetaData) {
  setAuth(init, metadata);
  metadata.imsOrg && init.headers.set('x-gw-ims-org-id', metadata.imsOrg);
}

function setSandbox(init: FetchInitNormalizedHeaders, metadata: DefaultMetaData) {
  setImsOrg(init, metadata);
  if (init.auth === 'Header') {
    metadata.sandbox && init.headers.set('x-sandbox-name', metadata.sandbox.name);
  }
}

function setSandboxPlus(init: FetchInitNormalizedHeaders, metadata: DefaultMetaData) {
  setSandbox(init, metadata);
  if (init.auth === 'Header') {
    metadata.sandbox && init.headers.set('x-sandbox-type', metadata.sandbox.type);
    metadata.sandbox && init.headers.set('x-sandbox-default', metadata.sandbox.isDefault.toString());
  }
}

function addHeadersBasedOnScope(init: FetchInitNormalizedHeaders, metadata: DefaultMetaData) {
  switch (init.scope) {
    case FetchScope.AUTH:
      setAuth(init, metadata);
      return;
    case FetchScope.ORG:
      setImsOrg(init, metadata);
      return;
    case FetchScope.SANDBOX:
      setSandbox(init, metadata);
      return;
    case FetchScope.SANDBOX_PLUS:
      setSandboxPlus(init, metadata);
      return;
    case FetchScope.NONE:
    default:
      return;
  }
}

function updateReq({apiKey, imsOrg, imsToken: token, sandbox}: FetchConfig, init: FetchInit): FetchInit {
  const metadata = {apiKey, imsOrg, sandbox, token, ...init.metadata};
  if (!(init.headers instanceof Headers)) {
    init.headers = new Headers(init.headers);
  }

  init.scope = init.scope || FetchScope.SANDBOX_PLUS;
  if (!metadata.token) {
    throw new Error('Fetch Api called without token');
  }
  addHeadersBasedOnScope(init as FetchInitNormalizedHeaders, metadata);
  if (init.requestId) {
    init.requestId === 'auto' && (init.requestId = uuidv1());
    init.headers.set('x-request-id', init.requestId);
  }
  if (init.auth === 'Body') {
    const params: FetchParams = {};
    const {sandbox: metaDataSandbox} = metadata;
    if (metaDataSandbox && ([FetchScope.SANDBOX_PLUS, FetchScope.SANDBOX].includes(init.scope))) {
      params.sandboxName = metaDataSandbox.name;
      if (init.scope === FetchScope.SANDBOX_PLUS) {
        params.sandboxType = metaDataSandbox.type;
        params.isDefaultSandbox = metaDataSandbox.isDefault.toString();
      }
    }
    if (!init.body) {
      init.body = JSON.stringify(params);
    } else {
      const parsedBody = JSON.parse(init.body as string);
      Object.assign(parsedBody, params);
      init.body = JSON.stringify(parsedBody);
    }
  }
  return init;
}

const executeFetch = async ({
  logger,
  fetchConfig,
  win,
  saFetch,
  input,
  init,
  messenger,
  ioEndpoint,
  ioRegionSpecificMap
}: FetchExecParams): Promise<Response> => {
  //populate network-runtime option to differentiate network runtime fetch.
  const startTimestamp = Date.now();
  let fetchInput = input;
  try {
    if (ioRegionSpecificMap &&
      fetchConfig.sandbox &&
      fetchConfig.sandbox.region &&
      ioRegionSpecificMap[fetchConfig.sandbox.region.toLowerCase()]
    ) {
      let ioRegionSpecificEndpoint = ioRegionSpecificMap[fetchConfig.sandbox.region.toLowerCase()];
      ioRegionSpecificEndpoint = `${ioRegionSpecificEndpoint}/graph/graphql?appId=${fetchConfig.appId}`;
      fetchInput = input instanceof Request ? new Request(ioRegionSpecificEndpoint, input as RequestInit) : ioRegionSpecificEndpoint;
    }
    // Run a slightly modified version of fetch which throws for certain status codes
    // By default throw on 429, 502, etc. - Could be configured for different codes via init.
    return await statusAwareFetch(
      fetchInput,
      {...init, 'network-runtime': true},
      fetchConfig.imsToken,
      win,
      logger,
      messenger
    );
  } catch (error) {
    // Note: We have disabled the fallback to the ioEndpoint here, and may re-enable
    // after gathering more data.
    const addedInfo = error instanceof InvalidStatusCodeError ? ` with status ${error.status}` : '';
    logger.event('Fetch.fallback',
      `Initial call failed${addedInfo}. Retrying with exponential backoff.`, {
        endpoint: fetchInput instanceof Request ? fetchInput.url : fetchInput,
        ioEndpoint
      },
      {level: Level.WARN});
    // Since fetchWithRetry will (hopefully) not be required for most fetches,
    // dynamically import it to save bytes.
    const {fetchWithRetry} = await import('./fetchRetry');
    return fetchWithRetry(
      ioEndpoint || fetchInput,
      init,
      error as Error,
      startTimestamp,
      saFetch,
      win,
      logger
    );
  }
};

export default (metricsApi: MetricsApi, win: Window & typeof globalThis, messenger?: RuntimeMessenger) => {
  const logger = metricsApi.create('exc.module-runtime.network.fetch');

  let fetchConfig: FetchConfig = {
    apiKey: '',
    appId: '',
    imsOrg: '',
    imsToken: '',
    sandbox: {isDefault: true, name: '', region: '', state: '', title: '', type: ''}
  };

  const saFetch = (input: RequestInfo, init: StatusAwareFetchInit) => statusAwareFetch(
    input,
    init,
    fetchConfig.imsToken,
    win,
    logger,
    messenger
  );

  const fetchApi = async (
    input: RequestInfo,
    fetchInit?: FetchInit,
    ioEndpoint?: string,
    ioRegionSpecificMap?: Record<string, string>
  ): Promise<Response> => {
    const init = fetchInit && (fetchInit.auth || (fetchInit.scope && fetchInit.scope !== FetchScope.NONE)) ?
      updateReq(fetchConfig, fetchInit) :
      fetchInit;

    return executeFetch({
      fetchConfig,
      init,
      input,
      ioEndpoint,
      ioRegionSpecificMap,
      logger,
      messenger,
      saFetch,
      win})
      .catch(err => {
        if (ioEndpoint) {
          logger.event('Fetch.fallbackFailed',
            'Failed to call both Primary and Secondary domain', {
              endpoint: input instanceof Request ? input.url : input,
              ioEndpoint
            },
            {level: Level.ERROR});
        }
        if (err instanceof InvalidStatusCodeError) {
          throw err;
        }
        throw new Error(`error in making a fetch call. Error is:- ${err}`);
      });
  };

  return {
    configure: (config: Configuration): void => {
      fetchConfig = {...fetchConfig, ...config};
    },
    fetch: fetchApi
  };
};
