/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CallbackObject, RuntimeMessenger} from '../models/runtimeModels';
import type {ConfigProxyManager} from '../config';
import {Events} from '@exc/shared';
import type {UserProfileApi} from '@adobe/exc-app/userprofile';

export default (messenger: RuntimeMessenger, configManager: ConfigProxyManager) => ({
  setButtons: buttons => {
    let customButtons = configManager.get('customButtons') || [];
    // Reset customButtons
    if (!buttons || buttons.length === 0) {
      customButtons = customButtons.filter(button => button.scope !== 'userProfile');
      configManager.set({customButtons});
      messenger.setCallback(Events.CUSTOM_PROFILE_BUTTON_CLICK, null);
      return;
    }
    // Add buttons
    const callbacks: CallbackObject = {};
    buttons.forEach(({callback, id, label}) => {
      customButtons.unshift({
        id,
        label,
        scope: 'userProfile'
      });
      callbacks[id] = callback;
    });
    configManager.set({customButtons});
    messenger.setCallback(Events.CUSTOM_PROFILE_BUTTON_CLICK, callbacks);
  }
} as UserProfileApi);
