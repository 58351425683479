/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Events, FlattenedConfigProxy} from '@exc/shared';
import type {RuntimeMessenger} from './models/runtimeModels';

export interface ConfigProxyManager {
  clear <T extends keyof FlattenedConfigProxy>(key?: T, cb?: string): void;

  config (): Readonly<FlattenedConfigProxy>;

  get <T extends keyof FlattenedConfigProxy>(key: T): FlattenedConfigProxy[T];

  set (updates: FlattenedConfigProxy): void;

  update<T extends keyof FlattenedConfigProxy>(key: T, value: any, callbackName: string, forceReset?: boolean): void;

  setupConfig<T extends keyof FlattenedConfigProxy>(
    obj: any, keys: string[], setCallback?: (key: string, value: any) => void, rollupObject?: T
  ): void;
}

class ConfigProxyManagerImpl implements ConfigProxyManager {
  private _config: FlattenedConfigProxy = {};
  private messenger: RuntimeMessenger;

  constructor(messenger: RuntimeMessenger) {
    this.messenger = messenger;
  }

  clear <T extends keyof FlattenedConfigProxy>(key?: T, cb?: string): void {
    if (key) {
      delete this._config[key];
      cb && this.messenger.setCallback(cb, null);
      return;
    }
    this._config = {} as FlattenedConfigProxy;
  }

  config = (): Readonly<FlattenedConfigProxy> => this._config;

  get <T extends keyof FlattenedConfigProxy>(key: T): FlattenedConfigProxy[T] {
    if (key.includes('/')) {
      const nestedKeys = key.split('/');
      const rollupObject: T = nestedKeys[0] as T;
      const apiName = nestedKeys[1];
      return this._config[rollupObject] && this._config[rollupObject][apiName];
    }
    return this._config[key];
  }

  set (updates: FlattenedConfigProxy): void {
    for (const updateKey in updates) {
      if (updateKey.includes('/')) {
        const nestedKeys = updateKey.split('/');
        const rollupObject = nestedKeys[0] as keyof FlattenedConfigProxy;
        const apiName = nestedKeys[1];
        this._config = {
          ...this._config,
          [rollupObject]: {...this._config[rollupObject], [apiName]: updates[updateKey as keyof FlattenedConfigProxy]}
        };
      }
    }
    this.messenger.send(Events.SHELL_SETTINGS, updates);
    this._config = {...this._config, ...updates};
  }

  update<T extends keyof FlattenedConfigProxy>(key: T, value: any, callbackName: string, forceReset?: boolean): void {
    const newValue = {...value};
    const cb = newValue.callback;
    if (cb || forceReset) {
      delete newValue.callback;
      this.messenger.setCallback(callbackName, cb);
    }
    const current = this.get(key) || {};
    this.set({[key]: Object.assign(current, newValue)});
  }

  setupConfig<T extends keyof FlattenedConfigProxy>(
    obj: any, keys: string[], setCallback?: (key: string, value: any) => void, rollupObject?: T
  ): void {
    keys.forEach(apiName => {
      Object.defineProperty(obj, apiName, {
        get: () => {
          // Remove rollupObject argument and if statements once 'sidebar' api
          // can be fully removed since this is now handled in the configProxy set and get
          if (rollupObject) {
            const object = this.get(rollupObject);
            return object[apiName];
          }
          return this.get(apiName as T);
        },
        set: value => {
          setCallback && setCallback(apiName, value);
          if (rollupObject) {
            this.set({
              [rollupObject]: {[apiName]: value}
            });
            return;
          }
          this.set({[apiName]: value});
        }
      });
    });
  }
}

export default (messenger: RuntimeMessenger) => new ConfigProxyManagerImpl(messenger);
