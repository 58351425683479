/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {BROWSER_FILTER_PARAMS} from '@exc/shared';
import type {Configuration} from '@exc/metrics-runtime';
import type {LocationLike, ObjectWithHref, ObjectWithPath} from '@adobe/exc-app/page';
import type {RuntimeConfiguration} from '@adobe/exc-app';
import type {RuntimeMessenger} from './models/runtimeModels';

/**
 * Checks if current window is Unified Shell or iframe.
 * @returns true if running in Shell, false if running in an iframe
 */
export function isShell(window: Window): boolean {
  const {config, navigator: {userAgent, webdriver}, parent} = window;
  return (parent === window && !(webdriver || userAgent.toLowerCase().includes(' electron/'))) || config?.tis === 1;
}

export function getSPAVersion(window: Window): string|undefined {
  if (window.config?.spaVersion) {
    return window.config.spaVersion;
  }
  if (window.location.origin.includes('localhost')) {
    return 'local';
  }
}

export function addVersionToMetricsConfig(config: Configuration, window: Window): Configuration {
  const version = getSPAVersion(window);
  return version && config.application ? {...config, application: {...config.application, version}} : config;
}

function splitPath(path: string, separator: string): string {
  const startsWithSlash = path.startsWith('/');
  const idx = path.indexOf(separator);
  const res = idx > -1 ? path.slice(idx + separator.length) : path;
  return res.startsWith('/') || !startsWithSlash ? res : `/${res}`;
}

const AEM_DOMAIN = /^https:\/\/author-p\d{4,10}-e\d{4,10}(-cmstg)?.adobeaemcloud.com/;

// Only capture urls without solutions as first path parameter. For these cases
// this isnt a shell URL -- it is a frame src of an app on SPA Pipeline that does
// not use the CDN yet.
const SHELL_URL_REGEX = [
  /^https:\/\/experience(-qa|-stage)?.adobe.com(?!\/solutions)/,
  new RegExp(`${AEM_DOMAIN.source}/ui`)
];

const returnShellUrl = (locationHref: string, externalQueryParams: string) => {
  const windowUrl = new URL(locationHref);
  if (externalQueryParams) {
    const search = new URLSearchParams(externalQueryParams);
    search.forEach((value, param) =>
      windowUrl.searchParams.set(param, value));
  }
  return windowUrl.toString();
};

type Item = (string|undefined)[];

const generateHashForPath = (baseUrl: URL, org?: string, sandbox?: string, subOrg?: string) => {
  // Split baseUrl.hash by tenant and solution path to insert sandbox.
  const {hash} = baseUrl;
  const [, tenant, _sandbox, _subOrg, solutionPath] = hash.match(`^#(/@[^/]*)?(/sname:[^/]+)?(/so:[^/]+)?(/[^$]+)`) || [];

  /**
   * Get the active sandbox or subOrg in the order defined by `items`. It finds
   * the first defined value and returns the built string for it.
   */
  const getActive = (items: Item[]): string => {
    const [prefix, item] = items.find(([, val]) => val) || [];
    // No defined item was found, return empty string.
    if (!item) {
      return '';
    }
    // The regex pulls off the entire prefix + value string, so only build the
    // string if it's not from the regex.
    return item.startsWith(`/${prefix}`) ? item : `/${prefix}:${item}`;
  };

  // Order in which to check the sandbox and sub-org values. Should always use
  // the ones defined by the user first, then fall back to the ones that exist
  // on the hash already.
  const sandboxSubOrgOrder = [
    ['sname', sandbox],
    ['so', subOrg],
    ['sname', _sandbox],
    ['so', _subOrg]
  ];

  // If org is set, only add new sandbox or sub-org (1st 2 items in the array)
  // as each org has different values and we shouldn't inherit the pre-existing
  // ones. If the org is not being set, keep the same tenant but use the first
  // new then existing sandbox or sub-org that is defined.
  return org ?
    `#/@${org}${getActive(sandboxSubOrgOrder.slice(0, 2))}${solutionPath}` :
    `#${tenant || ''}${getActive(sandboxSubOrgOrder)}${solutionPath}`;
};

const generateNewAppPath = (
  shellUrl: URL,
  locationPath: string,
  tenant: string,
  externalQueryParams: string,
  sandbox?: string
) => {
  shellUrl.hash = sandbox ? `${tenant}/sname:${sandbox}${locationPath}` : `${tenant}${locationPath}`;
  shellUrl.search = externalQueryParams;
  return shellUrl.toString();
};

/**
 * Called when receiving a message from the parent frame.
 * @param config The current external configuration.
 * @param location A ShellUrlObject to describe the location lookup.
 * @param newApp true if the URL is for a new application window.
 * @returns A valid full shell URL (as a string).
 */
export function generateShellUrl(config: RuntimeConfiguration, location: LocationLike, newApp?: boolean): string {
  let locationHref = (location as ObjectWithHref).href || '';
  const {baseFrameUrl, discovery, externalQueryParams, historyType} = config;
  const baseUrl = new URL(config.baseUrl);
  const isHashHistory = historyType === 'HASH';
  const configUrl = new URL(baseFrameUrl);
  const shellUrl = new URL(baseUrl.origin);
  const {org, path, sandbox, subOrg} = location as ObjectWithPath;

  // If the href is already a shell URL, there is nothing more to do.
  if (SHELL_URL_REGEX.some(regex => regex.test(locationHref))) {
    return returnShellUrl(locationHref, externalQueryParams);
  }

  // The AEM domain has a path where the standard experience.adobe does not.
  // Add the path for the AEM variation.
  if (AEM_DOMAIN.test(shellUrl.origin)) {
    shellUrl.pathname = '/ui';
  }

  if (!locationHref) {
    // Solutions URLs are "mounted" at the URL provided by the solution config
    // or by the discovery endpoint. The `baseFrameUrl` is the root URL that is
    // mounted at the `/@tenant/solution` path. If `baseFrameUrl` came from a
    // discovery call, it will be a fully formed path to a page with their
    // application. We can't just append the new path to that URL and instead
    // must treat the path as an absolute path (e.g. completely replacing the
    // existing path). In the non-discovery case, the `baseFrameUrl` is a
    // non-fully formed path within the application (e.g. /solutions/ddam). Any
    // new path should be appended to the `baseFrameUrl`'s path.
    const base = discovery ? configUrl.origin : baseFrameUrl;
    let locationPath = path || '';

    // Instead of updating the current shellUrl, newApp generates a shellUrl
    // for use in opening a window or tab for another solution with the provided
    // path while maintaining the query parameters present in the current url.
    if (newApp) {
      return generateNewAppPath(shellUrl, locationPath, `/@${config.tenant}`, externalQueryParams, sandbox);
    }
    locationPath = isHashHistory && locationPath.indexOf('#') === -1 ? `/#${locationPath}` : locationPath || '/';
    locationHref = `${base}${locationPath}`;
  }
  const url = new URL(locationHref);
  const removeParams = [...(config.browserParamFilterList || []), ...Array.from(BROWSER_FILTER_PARAMS)];
  removeParams.forEach((param: string) => url.searchParams.has(param) && url.searchParams.delete(param));
  const hash = generateHashForPath(baseUrl, org, sandbox, subOrg);
  let pathname = isHashHistory ? url.hash.replace(/^#\//, '/') : url.pathname;
  pathname = `${hash}${discovery ? pathname : splitPath(pathname, configUrl.pathname)}`;

  // Need to preserve the search and hash values on the URL being loaded.
  shellUrl.hash = `${pathname}${url.search}${isHashHistory ? '' : url.hash}`;
  shellUrl.search = externalQueryParams;

  return shellUrl.toString();
}

export const sendAndReceive = <V, T>(eventOut: string, messenger: RuntimeMessenger, value?: V, eventIn = eventOut): Promise<T> => {
  messenger.send(eventOut, value);
  return new Promise<T>((res, rej) => messenger.setCallback(eventIn, (data: T | {rejected: true, error: string}) => {
    data != null && typeof data === 'object' && 'rejected' in data && 'error' in data ? rej(new Error(data.error)) : res(data);
  }));
};
