/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AgreementsApi} from './agreements';
import type {AIApi} from '@adobe/exc-app/ai';
import type {AppApi} from '@adobe/exc-app/appapi';
import type {BlockNavigationOptions, LocationLike, PageApi, ShellRedirectOptions} from '@adobe/exc-app/page';
import type {CacheApi} from '@adobe/exc-app/cache';
import type {Callback, RuntimeConstructorParams, RuntimeEngine, RuntimeMessenger} from './models/runtimeModels';
import type {ComponentApi} from '@adobe/exc-app/component';
import {ConfigProxy, Events, markEventsPerformance} from '@exc/shared';
import type {ConfigProxyManager} from './config';
import type {ConsentApi} from '@adobe/exc-app/consent';
import Emitter, {Handler} from '@exc/emitter';
import type {FeatureFlagsApi} from '@adobe/exc-app/featureflags';
import type {HelpCenterApi} from '@adobe/exc-app/helpcenter';
import type {InternalApi} from '@adobe/exc-app/internal';
import {Level} from '@adobe/exc-app/metrics';
import type {Metrics} from '@adobe/exc-app/metrics';
import type {ModulesApi} from '@adobe/exc-app/modules';
import type {PermissionsApi} from '@adobe/exc-app/permissions';
import type {PulseApi} from '@adobe/exc-app/pulse';
import type {RuntimeConfiguration, Runtime as RuntimeInterface} from '@adobe/exc-app/index';
import type {SessionApi} from '@adobe/exc-app/session';
import type {SettingsApi} from '@adobe/exc-app/settings';
import type {ShellApi} from '@adobe/exc-app/shell';
import type {TopbarApi} from '@adobe/exc-app/topbar';
import type {UserApi} from '@adobe/exc-app/user';

export interface InitialConfiguration {
  /**
   * @deprecated
   */
  canTakeover?: boolean;
  nps?: Record<string, never>;
}

export interface MenuData {
  config: {
    subject: string;
    type: 'CONTEXTUAL_FEEDBACK_SUBMISSION';
  };
  selectedTab: 'feedback';
}

interface SkipMenuList {
  [index: string]: HTMLElement;
}

export const RUNTIME_MARKED_EVENTS = [
  'Runtime.constructed',
  'Runtime.ready',
  'Runtime.readyBeforeListen',
  'Runtime.done',
  'Messenger.connected'
];

// When a global shell component is open (i.e. Unified Nav or Global Search),
// we need to hide the solution's main content from the accessibility tree.
const setHidden = (hide: boolean, body: HTMLElement) => body.setAttribute('aria-hidden', hide.toString());

export class Runtime extends Emitter implements RuntimeInterface {
  public readonly agreements: AgreementsApi;
  public readonly ai: AIApi;
  public readonly app: () => Promise<AppApi>; // Backwards compatibility: @adobe/exc-app 0.2.27 and earlier.
  public readonly appId?: string;
  public readonly appApi: AppApi; // 0.2.28+
  public readonly cache: CacheApi;
  public readonly component: ComponentApi;
  public readonly consent: ConsentApi;
  public readonly configured = false;
  public readonly featureFlags: FeatureFlagsApi;
  public readonly lastConfigurationPayload: RuntimeConfiguration | null = null;
  public readonly messenger: RuntimeMessenger;
  public readonly modules: ModulesApi;
  public readonly page: PageApi;
  public readonly permissions: PermissionsApi;
  public readonly pulse: PulseApi;
  public readonly session: SessionApi;
  public readonly settings: SettingsApi;
  public readonly shell: ShellApi;
  public readonly user: UserApi;
  // Configuration proxy -- see proxiedConfig for accessors
  public appContainer: ConfigProxy['appContainer'];
  public customEnvLabel: ConfigProxy['customEnvLabel'];
  public customButtons: ConfigProxy['customButtons'];
  public customSearch: ConfigProxy['customSearch'];
  public digitalData: ConfigProxy['digitalData'];
  public feedback: ConfigProxy['feedback'];
  public helpCenter: ConfigProxy['helpCenter'];
  public logoutUrl: ConfigProxy['logoutUrl'];
  public nps: ConfigProxy['nps'];
  public showLanguagePicker: ConfigProxy['showLanguagePicker'];
  public showRolesPicker: ConfigProxy['showRolesPicker'];
  public sidebar: ConfigProxy['sidebar'];
  public sidenav: ConfigProxy['sidenav'];
  public solution: ConfigProxy['solution'];
  public subOrgs: ConfigProxy['subOrgs'];
  public workspaces: ConfigProxy['workspaces'];
  /**
   * Called when user's auth expires or times out and user has to
   * log in again.
   */
  public readonly authExpired: () => void;
  /**
   * Method called from solution to open link in a new tab.
   * @param path Relative path.
   */
  public readonly openInNewTab: (path: string, newApp?: boolean) => void;
  /**
   * Redirects to another unified shell solution.
   * @param path Path including search and hash to a unified shell solution
   * url.
   */
  public readonly shellRedirect: (pathOrUrl: string, options?: ShellRedirectOptions) => void;
  public readonly generateShellUrl: (location: LocationLike, newApp?: boolean) => string;
  public readonly blockNavigation: (enabled: boolean, options?: BlockNavigationOptions) => void;
  public readonly done: () => void;
  /**
   * Launch 404 page from anywhere
   */
  public readonly notFound: () => void;
  /**
   * Called when user needs to reload iframe URL.
   */
  public readonly iframeReload: (cacheBust?: boolean) => void;
  public readonly send: (type: string, data?: any) => void;
  private readonly addShellPlaceholder: () => void;
  private readonly metrics: Metrics;
  private readonly configManager: ConfigProxyManager;
  private readonly helpCenterApi: HelpCenterApi;
  private readonly topbar: TopbarApi;
  private readonly internal: InternalApi;
  private readonly engine: RuntimeEngine;
  private readonly userAgent: string;
  private readonly document: Document;
  private readonly runImmediately: (handler: TimerHandler) => void;
  private onCustomProfileClick?: Callback;
  private skipMenuList?: SkipMenuList;
  private onReadySet = false;
  private pendingConfig?: RuntimeConfiguration;

  constructor(params: RuntimeConstructorParams, configuration?: InitialConfiguration) {
    super();

    const {
      appId, configManager, engine, helpCenterApi, internal, messenger, metricsApi, pageApi: {addShellPlaceholder, page}, topbar, window
    } = params;
    const {
      app,
      modules: {
        agreements, ai, appApi, cache, component, consent, featureFlags, modules, permissions, pulse, session, settings, shell, user
      }
    } = engine;
    const {document, location, navigator, setTimeout} = window;
    configManager.clear();

    // Mirroed page methods
    const {blockNavigation, done, generateShellUrl, iframeReload, notFound, openInNewTab, shellRedirect} = page;
    this.blockNavigation = blockNavigation;
    this.openInNewTab = openInNewTab;
    this.shellRedirect = shellRedirect;
    this.generateShellUrl = generateShellUrl;
    this.iframeReload = iframeReload;
    this.done = done;
    this.notFound = notFound;
    this.addShellPlaceholder = addShellPlaceholder;
    this.appId = appId;
    this.send = messenger.send;
    this.engine = engine;
    this.internal = internal;
    this.agreements = agreements();
    this.ai = ai();
    this.app = app;
    this.appApi = appApi();
    this.cache = cache();
    this.component = component();
    this.consent = consent();
    this.featureFlags = featureFlags();
    this.helpCenterApi = helpCenterApi;
    this.modules = modules();
    this.page = page;
    this.permissions = permissions();
    this.pulse = pulse();
    this.session = session();
    this.settings = settings();
    this.shell = shell();
    this.user = user();
    this.topbar = topbar;

    this.runImmediately = handler => setTimeout(handler, 0);
    this.userAgent = navigator.userAgent;
    this.document = document;
    this.authExpired = this.user.authExpired;
    this.metrics = metricsApi.create('exc.module-runtime');
    this.messenger = messenger;
    this.configManager = configManager;
    // Adds a Metrics filter to create performance marks for events listed in RUNTIME_MARKED_EVENTS
    markEventsPerformance(
      window,
      RUNTIME_MARKED_EVENTS,
      event => `rt_${event.toLowerCase().replace(/[ ().\-&]+/g, '_')}`
    );

    this.metrics.event('Runtime.constructed');
    this.messenger.on('message', this.receive);

    const {href, pathname, hash, search} = location;
    this.send(Events.READY, {configuration, hash, href, pathname, search});

    configManager.setupConfig(this, [
      'appContainer',
      'customEnvLabel',
      'customButtons',
      'customSearch',
      'digitalData',
      'favicon',
      'feedback',
      'helpCenter',
      'logoutUrl',
      'modal',
      'nps',
      'preventDefaultCombos',
      'showLanguagePicker',
      'showRolesPicker',
      'sidebar',
      'sidenav',
      'solution',
      'spinner',
      'subOrgs',
      'title',
      'unloadPromptMessage',
      'workspaces'
    ], apiName => {
      ['sidebar', 'nps'].includes(apiName) && this.send(Events.DEPRECATION_WARNING, apiName);
    });

    // Add the viewport takeover DOM element to the page if enabled in the
    // config.
    if (configuration && ('canTakeover' in configuration)) {
      this.send(Events.DEPRECATION_WARNING, 'canTakeover');
      configuration.canTakeover && this.addShellPlaceholder();
    }

    engine.onPageChange(this);
  }

  public on(type: string, handler: Handler) {
    super.on(type, handler);
    if (type === 'ready') {
      this.onReadySet = true;
      if (this.pendingConfig) {
        // Re-emit the last config in the next event loop.
        this.runImmediately(() => {
          this.emit('ready', this.pendingConfig);
          delete this.pendingConfig;
        });
      }
    }
  }

  public sendConfig(config: RuntimeConfiguration): void {
    const event = this.configured ? 'configuration' : 'ready';
    this.metrics.event(`Runtime.${event}`, config);
    this.emit(event, config);
    if (!this.onReadySet) {
      this.metrics.event(`Runtime.readyBeforeListen`, {level: Level.WARN});
      this.pendingConfig = config;
    }
  }

  public set config(config: ConfigProxy) {
    this.configManager.set(config);
  }

  public get config(): ConfigProxy {
    return this.configManager.config();
  }

  /**
   * Stores a customHeroClick function for later use
   * and sends a post message to the Sandbox that a customHeroClick
   * exists.
   * @param fn Callback function for custom behavior from solution.
   */
  public set heroClick(fn: Callback) {
    this.topbar.onHeroClick(fn);
  }

  /**
   * Stores a profileClick function which is
   * invoked when a user clicks and custom profile buttons in the User Profile
   * view in Shell.
   * @param fn Callback function for custom behavior from solution.
   */
  public set customProfileClick(fn: Callback) {
    this.onCustomProfileClick = fn;
  }

  /**
   * Allows a solution to open a Shell menu by sending a
   * post message to the Sandbox.
   * @param menuKey A string to identify which shell menu to open.
   * Acceptable values include 'helpCenter'.
   * @param menuData An object data the menu will need.
   */
  public openMenu(menuKey: string, menuData: MenuData): void {
    if (menuKey === 'helpCenter') {
      this.helpCenterApi.open(menuData);
    } else {
      this.metrics.warn(`Runtime: unknown openMenu key ${menuKey}`);
    }
  }

  public onExternalHistoryChange = (path: any): void => {
    this.metrics.event('Runtime.onExternalHistoryChange', path);
    this.emit('history', {path, type: 'external'});
  };

  /**
   * Allows the a11y skip menu to set focus on an element inside the iframe
   * @param dataKey A string to identify which element to focus
   */
  private setKeyboardFocus(dataKey: string): void {
    const node = (this.skipMenuList || {})[dataKey];
    if (node) {
      // Right now "main" is not part of the sequential keyboard
      // navigation, so -1 is the value we want in the "main" case
      // as we programmatically move focus while not adding "main"
      // to the sequential order
      node.tabIndex = -1;
      node.focus();
      node.addEventListener('blur', () => node.removeAttribute('tabIndex'), {once: true});
    }
  }

  /**
   * Scrubs the DOM inside the iframe for a11y skip menu elements.
   * Currently finds the main landmark (if it exists), stores the list, and
   * sends a post message to the Sandbox with the skip menu options.
   */
  private getSkipMenuList(): void {
    let skipMenuList = null;
    // Get the main landmark or body of the solution for the skip menu list
    // Check the browser because Safari prevents programatic focusing
    // (https://github.com/braintree/braintree-web/issues/490) and 'Safari'
    // is also listed in the user agent string for other browsers ('Chrome'
    // also catches Edge and Opera browswers, find out more here
    // https://www.whatismybrowser.com/guides/the-latest-user-agent)
    // We fallback in Sandbox in the case of Safari
    const main = this.userAgent.includes('Firefox') || this.userAgent.includes('Chrome') ?
      this.document.querySelector('main, [role="main"], body') : null;
    if (main) {
      this.skipMenuList = {mainLandmark: main as HTMLElement};
      skipMenuList = [{
        id: 'mainLandmark',
        label: 'Skip to main content'
      }];
    }
    this.send(Events.SKIP_MENU_SET_LIST, {skipMenuList});
  }

  private receive = (event: MessageEvent): void => {
    const data = event.data || {};
    const type: string = data.type;
    const value: any = data.value;
    const changed: boolean = data.changed;

    switch (type) {
      case Events.CONFIGURE:
        // Make the changed array accessible to SPAs.
        value.changedProperties = changed;
        // If there are changed values, send change events
        this.engine.onConfigure(this, value, this.internal);
        break;
      case Events.CUSTOM_FEEDBACK:
        this.metrics.info('Runtime.onUseCustomFeedback');
        this.emit('customFeedback', {});
        break;
      case Events.CUSTOM_PROFILE_CLICK:
        this.onCustomProfileClick && this.onCustomProfileClick(value);
        break;
      case Events.CUSTOM_SEARCH:
        this.emit('customSearch', value);
        break;
      case Events.CUSTOM_PROFILE_BUTTON_CLICK:
      case Events.CUSTOM_HELP_BUTTON_CLICK:
        this.emit('customButtonClick', data);
        break;
      case Events.GLOBAL_DIALOG_OPEN:
        setHidden(value, this.document.body);
        break;
      case Events.HISTORY:
        this.onExternalHistoryChange(value);
        break;
      case Events.NPS:
        this.metrics.analytics.trackEvent({
          action: 'submit',
          element: value,
          feature: 'nps',
          type: 'nps',
          widget: {name: 'nps', type: 'form'}
        });
        break;
      case Events.SKIP_MENU_FOCUS:
        this.setKeyboardFocus(value);
        break;
      case Events.SKIP_MENU_GET_LIST:
        this.getSkipMenuList();
        break;
      case Events.USER_INPUT:
        this.engine.onShellUserInput(value);
        break;
    }
  };
}
