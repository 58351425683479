/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {Events} from '@exc/shared';
import type {FetchInit} from '@adobe/exc-app/network';
import InvalidStatusCodeError from './invalidStatusCodeError';
import {Level} from '@adobe/exc-app/metrics/Level';
import type {Metrics} from '@adobe/exc-app/metrics';
import type {RuntimeMessenger} from '../models/runtimeModels';

export type StatusAwareFetchInit = FetchInit & {'network-runtime'?: boolean};
export type StatusAwareFetchFn = (input: RequestInfo, init: StatusAwareFetchInit) => Promise<Response>;

export async function statusAwareFetch(
  input: RequestInfo,
  init: StatusAwareFetchInit,
  defaultToken: string,
  win: Window,
  logger: Metrics,
  messenger?: RuntimeMessenger
): Promise<Response> {
  const {fetch} = win;
  const {statusCodesToRetry = []} = init;
  const response = await fetch(input, init);

  if (response.status === 401) {
    const headers = init.headers as Headers || new Headers();
    const token = headers.get('authorization');
    if (!token) {
      // Log a potential implementation error. imsClientId is first as that's
      // the backwards compatible way that this value was used. apiKey will
      // have the default value set by Shell.
      const appId = init.metadata?.imsClientId || init.metadata?.apiKey || '';
      logger.event(
        'fetchUtils.noToken',
        {level: Level.WARN},
        {
          appId,
          url: response.url
        });
    } else if (token.includes(defaultToken) && messenger) {
      messenger.send(Events.LOST_AUTH, response.url);
    }
  }

  // Only check status codes to retry if response is not ok.
  if (!response.ok && statusCodesToRetry.includes(response.status)) {
    throw new InvalidStatusCodeError(`${response.status} ${response.statusText}`, response);
  }

  return response;
}
