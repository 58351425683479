/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {Application, Record, User} from '@exc/metrics';
import type {Configuration} from '@exc/metrics-runtime';
import type {FilterFn, OptOutFn, WriteFn} from './lazyMetrics';
import Metrics from './lazyMetrics';
import type {MetricsApi} from '@adobe/exc-app/metrics';
import type MetricsConfiguration from '@adobe/exc-app/metrics/Configuration';
import type MetricsRuntime from '@exc/metrics-runtime';
import uuidv1 from 'uuid/v1';

export interface MetricsApiContainer {
  metricsApi: MetricsApi;
  metricsInternal: {
    clearUser(version?: string): Promise<void>;
    configureMetrics(config?: MetricsConfiguration): Promise<MetricsConfiguration>;
    flush(): Promise<number>;
    setApplication(app: Application): Promise<void>;
    setFilter <T>(filterFunction: (record: T) => Promise<T>): void;
    setUser(user: User, version?: string): Promise<void>;
  },
  write: WriteFn
}

const metricsRuntimePromise: Promise<typeof MetricsRuntime> = import('./metricsRuntime')
  .then(({MetricsRuntime}) => MetricsRuntime);

export const userOptOut = (optOut: boolean, {adobeMetrics}: Window): void =>
  adobeMetrics.optOut(optOut);

export const writeWithVersion = async (record: Record, {adobeMetrics}: Window): Promise<number> => {
  const {sdkVersion} = adobeMetrics as any;
  record.metricsState = Object.assign(record.metricsState || {}, {sdkVersion});
  if (record.metricsState.correlationId === 'uuid') {
    record.metricsState.correlationId = uuidv1();
  }
  return adobeMetrics.write(record);
};

export const initMetrics = (optOut: OptOutFn, write: WriteFn, win: Window) => {
  if (!win.adobeMetrics) {
    win.adobeMetrics = {optOut, write} as any;
  }
};

const configureMetrics = (
  lazyWrite: WriteFn,
  onConfigure: () => void,
  win: Window & typeof globalThis,
  config?: Configuration
): Promise<Configuration> => (
  metricsRuntimePromise
    .then(MetricsRuntime => {
      const {adobeMetrics} = win;
      if (adobeMetrics.write === lazyWrite) {
        delete (adobeMetrics as any).optOut;
        delete (adobeMetrics as any).write;
        delete (adobeMetrics as any).configure;
      }
      return MetricsRuntime.configure(config, win);
    })
    .then(returnedConfig => {
      config && onConfigure();
      return returnedConfig;
    }));

export default (win: Window & typeof globalThis): MetricsApiContainer => {
  let filter: FilterFn;
  let pendingOptOut: boolean | undefined;
  let queue: Record[] = [];
  let ready = false;
  let readyResolve: (value?: any) => void;
  let toFlush = false;
  const readyPromise = new Promise(resolve => readyResolve = resolve);

  const onConfigure = (): void => {
    if (!ready) {
      readyResolve();
      if (pendingOptOut !== undefined) {
        userOptOut(pendingOptOut, win);
        pendingOptOut = undefined;
      }
      queue.forEach(record => writeWithVersion(record, win));
      queue = [];
      toFlush && win.adobeMetrics.flush();
      ready = true;
    }
  };

  const lazyOptOut: OptOutFn = (optOut: boolean): void => {
    if (ready) {
      return win.adobeMetrics.optOut(optOut);
    }
    pendingOptOut = optOut;
  };

  const lazyWrite: WriteFn = async record => {
    const _record = filter ? await filter(record) : record;
    if (ready) {
      return writeWithVersion(_record, win);
    }
    queue.push(_record);
    return 0;
  };

  initMetrics(lazyOptOut, lazyWrite, win);

  async function executeOnReady(action: () => void): Promise<void> {
    ready || await readyPromise;
    action();
  }

  return {
    metricsApi: {
      create: (name, ...args: any) => new Metrics(lazyWrite, win, name, ...args)
    },
    metricsInternal: {
      clearUser: (version?: string): Promise<void> => executeOnReady(() => win.adobeMetrics.clearUser(version)),
      configureMetrics: config => configureMetrics(lazyWrite, onConfigure, win, config as Configuration),
      flush: async (): Promise<number> => {
        if (ready) {
          return win.adobeMetrics.flush();
        }
        toFlush = true;
        return 0;
      },
      setApplication: (app: Application): Promise<void> => executeOnReady(() => win.adobeMetrics.setApplication(app)),
      setFilter: <T>(filterFunction: (record: T) => Promise<T>): void => filter = filterFunction as any,
      setUser: (user: User, version?: string): Promise<void> => executeOnReady(() => win.adobeMetrics.setUser(user, version))
    },
    write: lazyWrite
  };
};
