/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AppController, AppData, PostMessageFn, withNonce} from '@exc/shared';
import hashString from 'string-hash';

type AppDataSecure = AppData & withNonce;

class AppControllerService implements AppController {
  private appToSandboxMap: Record<string, string> = {};
  private appData: Record<string, AppDataSecure> = {};

  public registerApp = (sandboxId: string, sandboxAppData: AppData): void => {
    const {appId} = sandboxAppData;
    const prevSandboxId = this.appToSandboxMap[appId];
    let {nonce = ''} = this.appData[sandboxId] || {};
    if (!nonce) {
      nonce = btoa(hashString(`${sandboxId}-${performance.now()}-${Math.random()}`).toString());
    }
    this.appToSandboxMap[appId] = sandboxId;
    this.appData[sandboxId] = {nonce, ...sandboxAppData};
    if (prevSandboxId && prevSandboxId !== sandboxId) {
      delete this.appData[prevSandboxId];
    }
  };

  public unregisterApp = (sandboxId: string): void => {
    delete this.appData[sandboxId];
    Object.entries(this.appToSandboxMap).forEach(([appId, mappedSandboxId]) => {
      sandboxId === mappedSandboxId && delete this.appToSandboxMap[appId];
    });
  };

  public verifyMessage = <T extends withNonce>(data: T, sandboxId: string): boolean =>
    !!data?.nonce && this.appData[sandboxId]?.nonce === data.nonce;

  public getSandboxId = (appId: string): string|undefined => this.appToSandboxMap[appId];

  public sendMessage<T>(sandboxId: string, message: T, origin: string, postMessageFn: PostMessageFn) {
    const {nonce} = this.appData[sandboxId] || {};
    nonce && postMessageFn({nonce, ...message}, origin);
  }
}

export default new AppControllerService();
