/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AdobeMetricsRuntime} from '@exc/metrics-runtime';
import createConfig, {ConfigProxyManager} from './config';
import createEngine from './runtimeDeferred';
import createHelpCenter from './helpcenter';
import createInternal from './internal';
import createMetrics from './metrics';
import createNetwork from './network';
import createNps from './nps';
import createOrgSwitcher from './orgswitcher';
import createPage from './page';
import createSidebar from './sidebar';
import createTopbar from './topbar';
import createUserProfile from './userprofile';
import type {InitialConfiguration} from './runtimeClass';
import {isShell} from './util';
import Messenger from './Messenger';
import type {PostMessageFn} from '@exc/shared';
import type {Runtime} from '@adobe/exc-app';
import {Runtime as RuntimeClass} from './runtimeClass';
import type {RuntimeConstructorParams} from './models/runtimeModels';

export type AdobeMetricsWithRecents = AdobeMetricsRuntime & {
  onRecents?: (event: string | string[], args: any[]) => Promise<void[]>;
};

const getRuntimeClass = (params: RuntimeConstructorParams): new (configuration?: InitialConfiguration) =>
  Runtime => class Runtime extends RuntimeClass {
  constructor(configuration?: InitialConfiguration) {
    super(params, configuration);
  }
};

const bootstrapRuntime = (
  win: Window & typeof globalThis, postMessageFn?: PostMessageFn, activeAppId?: string): void => {
  const appId = activeAppId || new URL(win.location.href).searchParams.get('appId') || undefined;
  const metricsApiContainer = createMetrics(win);
  const {metricsApi, metricsInternal: {configureMetrics}} = metricsApiContainer;
  const messenger = new Messenger(metricsApiContainer, win, postMessageFn, appId);
  const configManager: ConfigProxyManager = createConfig(messenger);
  const pageApi = createPage(messenger, configManager, metricsApi, win);
  const engine = createEngine(messenger, configManager, [pageApi], metricsApi, win);
  const sidebar = createSidebar(messenger, configManager);
  const topbar = createTopbar(messenger, configManager);
  const orgSwitcher = createOrgSwitcher(configManager);
  const nps = createNps(messenger, configManager);
  const helpCenterApi = createHelpCenter(messenger, configManager, metricsApi);
  const userProfile = createUserProfile(messenger, configManager);
  const {configureNetwork, network} = createNetwork(metricsApi, win, engine, messenger);
  const internal = createInternal(configureNetwork, metricsApiContainer, win, engine, messenger);
  const runtimeClass = getRuntimeClass({
    appId,
    configManager,
    engine,
    helpCenterApi,
    internal,
    messenger,
    metricsApi,
    network,
    nps,
    orgSwitcher,
    pageApi,
    sidebar,
    topbar,
    userProfile,
    window: win
  });

  const runtimeInstance: {instance: Runtime|undefined} = {
    instance: undefined
  };

  function defaultFn(configuration?: InitialConfiguration): Runtime {
    runtimeInstance.instance || (runtimeInstance.instance = new runtimeClass(configuration));
    return runtimeInstance.instance;
  }

  const {env = ''} = win.config || {};
  configureMetrics({environment: ['STAGE', 'PROD'].includes(env) ? env.toLowerCase() : undefined});
  engine.waitForInit(runtimeInstance);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {app, modules: {networkPrefetch, ...globalModules}} = engine;
  const {page} = pageApi;

  const nest = (nestedWin: Window & typeof globalThis): Promise<boolean> =>
    import('./runtimeNest').then(({nestModuleRuntime}) => nestModuleRuntime(nestedWin, win));

  win['exc-module-runtime'] = {
    ...globalModules,
    app,
    default: defaultFn,
    helpCenter: helpCenterApi,
    internal,
    metrics: metricsApi,
    nest,
    network,
    nps,
    orgSwitcher,
    page,
    Runtime: runtimeClass,
    sidebar,
    topbar,
    userProfile
  };
};

const bootstrapShell = (win: Window & typeof globalThis): void => {
  const metricsApiContainer = createMetrics(win);
  const {metricsApi: metrics} = metricsApiContainer;
  const {configureNetwork, network} = createNetwork(metrics, win);
  const internal = createInternal(configureNetwork, metricsApiContainer, win);

  win['exc-module-runtime'] = {
    bootstrapRuntime,
    internal,
    metrics,
    network
  };
};

// eslint-disable-next-line no-restricted-globals
isShell(window) ? bootstrapShell(window) : bootstrapRuntime(window);
