/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AIContext} from '@adobe/exc-app/ai';
import type {AppApi} from '@adobe/exc-app/appapi';
import type {CommonModules, Modules} from '@adobe/exc-app/src/Global';
import type {CustomLabelCollection, Solution} from '@adobe/exc-app/topbar';
import type {DigitalData} from '@exc/metrics';
import type {InternalApi} from '@adobe/exc-app/internal';
import type {LogoutUrl} from '@adobe/exc-app/user';
import type Metric from '@adobe/exc-app/metrics/Metric';
import type {NavConfig} from '@adobe/exc-app/sidenav';
import type {RuntimeConfiguration} from '@adobe/exc-app/RuntimeConfiguration';
import type {SubOrgs} from '@adobe/exc-app/orgswitcher';

export const SHELL_HEIGHT = 49;

export type PostMessageFn = (message: any, targetOrigin: string, transfer?: Transferable[]) => void;

export interface ApplicationRuntime extends Modules {
  app: () => Promise<AppApi>;
  'exc-app-version'?: string;
  nest: (nested: Window & typeof globalThis) => Promise<boolean>;
}

export interface UnifiedShellRuntime extends CommonModules {
  bootstrapRuntime: (window: Window & typeof globalThis, postMessage: PostMessageFn, appId?: string) => void;
}

export interface NavigationTimingReduced {
  domInteractive: number;
  fetchStart: number;
  loadEventStart: number;
  responseEnd: number;
}

export interface LegacyTiming extends NavigationTimingReduced {
  navigationStart: number;
}

export type MessageQueueItem = [string, Record<string, any>];

export interface IframePerformance {
  done?: number;
  timeOrigin?: number;
  entries: PerformanceEntry[];
  timing: LegacyTiming;
}

export interface KeyCombo {
  altKey?: boolean;
  ctrlKey?: boolean;
  key: string;
  metaKey?: boolean;
  shiftKey?: boolean;
}

export interface ButtonDefinition {
  id: string;
  label: string;
  scope: string;
}

export interface ConfigProxy {
  appContainer?: string;
  customButtons?: ButtonDefinition[];
  customEnvLabel?: CustomLabelCollection;
  coachMark?: {
    element: string;
    enabled: boolean;
  };
  customSearch?: {
    enabled: boolean;
    show: boolean;
  };
  digitalData?: any;
  favicon?: string;
  feedback?: {
    buttonLabel: string;
    enabled: boolean;
    subject?: string;
    type: 'external' | 'custom' | 'openFeedback';
    url: string;
  };
  helpCenter?: {
    featured: {
      description?: string;
      href: string;
      label?: string;
      path?: string;
    }[];
  };
  logoutUrl?: LogoutUrl;
  modal?: boolean;
  modalAutoDetect?: boolean,
  // This is now deprecated in favor of the Total User Experience (TUX) survey
  // delivered by Unified Shell.
  // Remove this once all solutions have transitioned to TUX.
  nps?: {
    enabled: boolean;
    sampling?: number;
    showToAdobeUsers: boolean;
  };
  preventDefaultCombos?: KeyCombo[];
  pulse?: {
    button?: {label: string};
    count?: number;
  };
  showLanguagePicker?: boolean;
  showRolesPicker?: boolean;
  // This is now deprecated in favor of the sidenav config, which should be used instead.
  // Remove this once all solutions have been transitioned to sidenav.
  sidebar?: {
    config?: NavConfig;
    collapsed?: boolean;
    visible?: boolean;
  };
  sidenav?: {
    config?: NavConfig;
    collapsed?: boolean;
    visible?: boolean;
  };
  solution?: Solution;
  spinner?: boolean;
  subOrgs?: SubOrgs;
  title?: string;
  unloadPromptMessage?: string;
  workspaces?: {
    name: string;
    url: string;
  }[];
}

export type ExcModuleRuntime = UnifiedShellRuntime | ApplicationRuntime;

declare global {
  interface Window {
    _srp?: {
      cdn: string;
      fallbackLocations: string[];
    };
    config?: {
      env?: string;
      lastModified?: number;
      region?: string;
      time?: number;
      tis?: number;
      spaAppId?: string;
      spaVersion?: string;
    };
    digitalData?: DigitalData;
    EXC_MR_READY?: (() => void) & {autoDelete?: boolean};
    'exc-module-runtime': ExcModuleRuntime;
    somg?: Record<string, any>;
  }
}

const DEFAULT_EXPIRES_IN = 1000 * 60 * 60;
// Contains the events that should be marked.
const eventsMapping: {[key: string]: string} = {};

export const BROWSER_FILTER_PARAMS = new Set<string>([
  '_mr',
  'shell_domain',
  'shell_forceReload'
]);

/**
 * Setup the events marker by using the filter approach documented here:
 * https://git.corp.adobe.com/exc/metrics-sdk-js/blob/master/README.md#filter
 * @param win Window object
 * @param eventsToMark An array of events to be marked
 * @param transformFunc a function to transform event names used to enforce a naming convention.
 */
export function markEventsPerformance(win: Window, eventsToMark: Array<string>, transformFunc = (a: string): string => a): void {
  const {performance, 'exc-module-runtime': {internal}} = win;
  // Filter function
  function metricsWrite<T extends Metric>(record: T): Promise<T> {
    if (record.event) {
      const events = Array.isArray(record.event) ? record.event : [record.event];
      events.forEach((event: string) => event in eventsMapping && performance.mark(eventsMapping[event]));
    }
    return Promise.resolve(record);
  }
  eventsToMark.forEach(event => {
    eventsMapping[event] = transformFunc(event);
  });
  if (typeof performance?.mark === 'function') {
    internal.setFilter(metricsWrite);
  }
}

/**
 * Throttle function invocations. Used to batch certain operations such as queue processing.
 * @param fn - Function
 * @param timeout - Throttling timeout (ms)
 * @param immediateAfterIdle - If there were no invocations for the defined period
 * (timeoutMs), process immediatelly.
 * @param win - Window
 */
export const throttleQueue = (fn: () => void, timeout: number, immediateAfterIdle = false, win: Window = window): () => void => {
  const {setTimeout} = win;
  let ready = true;
  let lastInvocation = Date.now();
  return function (this: any): void {
    if (ready) {
      if (immediateAfterIdle && Date.now() - lastInvocation > timeout) {
        fn.apply(this);
        lastInvocation = Date.now();
        return;
      }
      ready = false;
      setTimeout(() => {
        fn.apply(this);
        lastInvocation = Date.now();
        ready = true;
      }, timeout);
    }
  };
};

export interface TokenMetadata {
  aa_id: string;
  as: string;
  client_id: string;
  created_at: number;
  expires_in: number;
  fg: string;
  id: string;
  imp_id?: string;
  moi: string;
  pba?: string;
  scope: string;
  sid: string;
  state?: string;
  type: string;
  user_id: string;
}

/**
 * Extracts metadata from the IMS token by base64 decoding the middle section
 * and parsing it into JSON.
 * @param {string} token The IMS token to extract metadata from.
 * @returns {Object} The extracted metadata.
 */
export function extractTokenMetadata(token: string): TokenMetadata | null {
  try {
    const middleBits = token.split('.')[1];
    const tokenMetadata = JSON.parse(atob(middleBits));
    tokenMetadata.created_at = parseInt(tokenMetadata.created_at, 10) || Date.now();
    tokenMetadata.expires_in = parseInt(tokenMetadata.expires_in, 10) || DEFAULT_EXPIRES_IN;
    return tokenMetadata as TokenMetadata;
  } catch {
    return null;
  }
}

export const wait = (ms: number, win: Window = window): Promise<void> => new Promise(resolve => win.setTimeout(resolve, ms));

export const NOOP = (): void => {
  // Intentional no operation
};

export function getOperationName(query = ''): string|undefined {
  // Extract operation name from query
  const name = /(query|mutation) ?(\w+)/.exec(query);
  return (name && name.length) ? name[2] : undefined;
}

export const getErrorMessage = (err: any, defaultMessage = 'Unknown Error'): string => {
  if (err && typeof err === 'object' && typeof err.message === 'string') {
    return err.message;
  }

  if (typeof err === 'string') {
    return err;
  }

  return defaultMessage;
};

/**
 * Awaits a promise up to a certain timeout.
 * If the timeout is due before the promise resolves, resolves with a predefined payload.
 * @template T
 * @param promise to await.
 * @param timeout - Timeout (ms)
 * @param timeoutResponse - Timeout response
 * @param win - Window
 */
export const runWithTimeout = <T>(promise: Promise<T>, timeout: number, timeoutResponse: T, win: Window = window): Promise<T> => Promise.race([
  promise,
  new Promise<T>(resolve => win.setTimeout(() => resolve(timeoutResponse), timeout))
]);

export interface FlattenedConfigProxy extends ConfigProxy {
  'ai/context'?: AIContext;
  'sidenav/config'?: NavConfig;
  'sidenav/collapsed'?: boolean;
  'sidenav/visible'?: boolean;
  'pulse/button'?: {label: string};
  'pulse/count'?: number;
}

export type {
  CustomKeyboardEvent,
  CustomMouseEvent,
  UserKeyEvent,
  UserMouseEvent
} from './Events';

export {Events, EventsThirdParty} from './Events';

export type {
  AvatarData,
  ImsOrganization
} from './IMS';

export interface SpaInfo {
  spaAppId?: string;
  spaVersion?: string;
}

export interface AppData extends SpaInfo {
  appId: string;
}

export interface DonePayload {
  addMainElement: boolean;
  iframePerformance: string;
  version?: string;
}

export type withNonce = {
  nonce: string;
};

export interface AppController {
  registerApp(sandboxId: string, sandboxAppData: AppData): void;
  unregisterApp(sandboxId: string): void;
  verifyMessage<T extends withNonce>(data: T, sandboxId: string): boolean;
}

export type InternalContainer = InternalApi & AppController & {
  waitForConfigure: () => Promise<void> | undefined;
};

export const KEYS_WITH_PII = new Set<keyof RuntimeConfiguration>([
  'imsInfo',
  'imsProfile',
  'imsToken',
  'shellInfo'
]);

export const sanitizeConfiguration = (config: Partial<RuntimeConfiguration>): Partial<RuntimeConfiguration> => {
  const sanitized = {...config};
  KEYS_WITH_PII.forEach((piiKey: keyof RuntimeConfiguration) => delete sanitized[piiKey]);
  return sanitized;
};

export const digitalDataUpdated = (detail: string, win: Window = window) =>
  win.dispatchEvent(new CustomEvent('digitalData', {detail}));

/**
 * Validate if the region is an AWS org region for determining the correct URL to hit.
 * @param region - Region to validate.
 * @returns Whether or not the region is an AWS org region.
 */
const AWS_REGIONS = new Set(['VA6']);
export const isAWSOrgRegion = (region: string): boolean => AWS_REGIONS.has(region);
