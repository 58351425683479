/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {addVersionToMetricsConfig} from './util';
import appControllerService from './internal/AppControllerService';
import {ApplicationRuntime, Events, MessageQueueItem, PostMessageFn, SpaInfo, throttleQueue} from '@exc/shared';
import type {Configuration} from '@exc/metrics-runtime';
import Emitter from '@exc/emitter';
import {Level, Metrics} from '@adobe/exc-app/metrics';
import type {MessengerCallback, RuntimeMessenger} from './models/runtimeModels';
import type {MetricsApiContainer} from './metrics';

interface PongMessage {
  autoInstantiateRuntime?: boolean;
  metricsConfig: Configuration;
}

const PING_RETRIES = 2;
const PING_TIMEOUT_MS = 500;

export default class RuntimeMessengerImpl extends Emitter implements RuntimeMessenger {
  public inQueue: MessageEvent[] = [];
  public isConnected = false;
  public origin = '*';
  public queue: MessageQueueItem[] = [];
  public callbacks: {[type: string]: MessengerCallback} = {};
  public appId?: string;
  public readonly metrics: Metrics;
  private sandboxId?: string;
  private readonly metricsApiContainer: MetricsApiContainer;
  private readonly postMessage: PostMessageFn;
  private readonly addVersionToMetricsConfig: (config: Configuration) => Configuration;
  private readonly getGlobalRuntime: () => ApplicationRuntime;

  public constructor(metricsApiContainer: MetricsApiContainer, win: Window, postMessageFn?: PostMessageFn, appId?: string) {
    super();
    this.metricsApiContainer = metricsApiContainer;
    this.appId = appId;
    const {metricsApi} = this.metricsApiContainer;
    this.metrics = metricsApi.create('exc.module-runtime.messenger');
    win.addEventListener('message', this.receive, false);
    this.postMessage = postMessageFn || ((message, origin, transfer) => win.parent?.postMessage(message, origin, transfer));
    this.addVersionToMetricsConfig = config => addVersionToMetricsConfig(config, win);
    this.getGlobalRuntime = () => win['exc-module-runtime'] as ApplicationRuntime;

    this.connect(win);
    // Wait 16ms/1 frame every time a post message is sent before clearing queue
    this.flushQueue = throttleQueue(this.flushQueue, 16, false, win);
  }

  private _sendMessage<T>(type: string, value?: T): void {
    const message = {appId: this.appId, type, unifiedShell: 1, value};
    this.sandboxId ?
      appControllerService.sendMessage(this.sandboxId, message, this.origin, this.postMessage) :
      this.postMessage(message, this.origin);
  }

  public flushQueue(): void {
    // Ensure this.origin is set on the off-chance that we flush queue
    // before receiving the first post message.
    if (this.origin === '*') {
      return;
    }

    this.queue.length > 0 && this._sendMessage(Events.QUEUE, this.queue);
    this.queue = [];
  }

  public send = (type: string, value?: any): void => {
    // Consolidate Shell Settings Config Event if possible
    if (type === Events.SHELL_SETTINGS) {
      const currentValue = this.queue.find(([key]) => key === Events.SHELL_SETTINGS);
      if (currentValue) {
        Object.assign(currentValue[1], value);
        return;
      }
    } else if (type === Events.NOT_INITIALIZED) {
      // Bypass queue.
      this._sendMessage(type);
      return;
    }
    this.queue.push([type, value]);
    this.flushQueue();
  };

  private connect = (win: Window, retriesLeft = PING_RETRIES) => {
    const {config = {}, setTimeout} = win;
    const {spaAppId, spaVersion} = config;
    if (this.appId) {
      this.sandboxId = appControllerService.getSandboxId(this.appId);
    }
    // Sends ping event with * as origin because it doesn't contain
    // anything secure, and we need the response of the first received post
    // message to determine what that valid origin is.
    this._sendMessage<SpaInfo>(Events.PING, {spaAppId, spaVersion});
    setTimeout(() => {
      if (!this.isConnected) {
        retriesLeft ?
          this.connect(win, retriesLeft - 1) :
          this.metrics.event('Runtime.PingTimeout', {level: Level.ERROR});
      }
    }, PING_TIMEOUT_MS);
  };

  private handlePong = (pongMessage: PongMessage) => {
    this.isConnected = true;
    if (pongMessage) {
      const {autoInstantiateRuntime, metricsConfig} = pongMessage;
      const {metricsInternal: {configureMetrics}} = this.metricsApiContainer;
      const config = this.addVersionToMetricsConfig(metricsConfig);
      // Many SPAs load Runtime.js early but instantiate the Runtime object later.
      // For these scenarios, we send useful information to the iframe via the PONG
      // event allowing the iframe to send data to EIM and optionally create Runtime early.
      configureMetrics(config);
      // PONG event is only dependent on the iframe loading Runtime.js
      const globalRuntime = this.getGlobalRuntime();
      if (autoInstantiateRuntime && globalRuntime?.default) {
        this.metrics.event('Runtime.AutoInstantiate');
        globalRuntime.default();
      }
    }
    this.metrics.event('Messenger.connected', {appId: this.appId});
    this.flushQueue();
    this.inQueue.forEach(evt => this.receive(evt));
    this.inQueue = [];
  };

  public receive = (event: MessageEvent): void => {
    // Ensure the messages always come from adobe.com/io or adobeaemcloud origins.
    if (!/(\/\/|\.)(adobe|adobeaemcloud)\.(com|io)(:\d{4})?$/.test(event.origin)) {
      return;
    }

    const data = event.data || {};
    const type: string = data.type;

    // Only accept messages from unified shell
    if (data.unifiedShell !== 1) {
      return;
    }

    if (!this.isConnected) {
      if (type === Events.PONG) {
        this.origin = event.origin;
        if (this.appId && this.appId !== data.appId) {
          // Need to monitor this error over time, should not happen.
          this.metrics.event('Messenger.appIdMismatch', {pongAppId: data.appId, urlAppId: this.appId}, {level: Level.ERROR});
        }

        this.appId = data.appId;
        this.handlePong(data.value as PongMessage);
      } else {
        this.metrics.info(`Event ${type || 'unknown'} received before connection - Queueing.`);
        this.inQueue.push(event);
        return;
      }
    }

    const value: any = data.value;
    const cb = this.callbacks[type];
    if (cb) {
      if (typeof(cb) === 'function') {
        cb(value);
      } else {
        cb[value.id] && cb[value.id](value);
      }
    }

    this.emit('message', event);
  };

  public setCallback = (type: string, callback?: MessengerCallback | null): void => {
    if (!callback) {
      delete this.callbacks[type];
      return;
    }
    this.callbacks[type] = callback;
  };
}
