/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ConfigProxyManager} from '../config';
import {Events} from '@exc/shared';
import type {RuntimeMessenger} from '../models/runtimeModels';
import type {SidebarApi} from '@adobe/exc-app/sidebar';

export default (messenger: RuntimeMessenger, configManager: ConfigProxyManager): SidebarApi => {
// This is now deprecated in favor of the sidenav api, which should be used instead.
// Remove this once all solutions have been transitioned to sidenav.
  const sidebar = {} as SidebarApi;

  configManager.setupConfig(sidebar, [
    'config',
    'collapsed',
    'visible'
  ], () => messenger.send(Events.DEPRECATION_WARNING, 'sidebar'), 'sidebar');
  return sidebar;
};
