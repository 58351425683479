/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
// Events that are sent by our own code as opposed to messages receieved through API calls
const InternalEvents = {
  ACTIVE: 'ACTIVE',
  CLICK: 'CLICK',
  COACH_MARK_POSITIONED: 'COACH_MARK_POSITIONED',
  COMPONENT_CLOSE_COMPLETE: 'COMPONENT_CLOSE_COMPLETE',
  DEPRECATION_WARNING: 'DEPRECATION_WARNING',
  DONE: 'DONE',
  FOCUS: 'FOCUS',
  GAINSIGHT_INIT: 'GAINSIGHT_INIT',
  GLOBAL_DIALOG_OPEN: 'GLOBAL_DIALOG_OPEN',
  HERO_CLICK: 'HERO_CLICK',
  HISTORY: 'HISTORY',
  KEYPRESS: 'KEYPRESS',
  LOST_AUTH: 'LOST_AUTH',
  NOT_INITIALIZED: 'NOT_INITIALIZED',
  NPS: 'NPS',
  PING: 'PING',
  PONG: 'PONG',
  QUEUE: 'QUEUE',
  READY: 'READY',
  RECENT_EVENT: 'RECENT_EVENT',
  SKIP_MENU_FOCUS: 'SKIP_MENU_FOCUS',
  TOAST_SUPRESSED: 'TOAST_SUPRESSED',
  USER_INPUT: 'USER_INPUT'
};

// Internal events and APIs that are approved for 3rd party use
export const EventsThirdParty = {
  ...InternalEvents,
  AUTH_EXPIRATION: 'AUTH_EXPIRATION',
  BLOCK_NAVIGATION: 'BLOCK_NAVIGATION',
  CAN_TAKEOVER: 'CAN_TAKEOVER',
  CONFIGURE: 'CONFIGURE',
  CUSTOM_FEEDBACK: 'CUSTOM_FEEDBACK',
  CUSTOM_HELP_BUTTON_CLICK: 'CUSTOM_HELP_BUTTON_CLICK',
  CUSTOM_SEARCH: 'CUSTOM_SEARCH',
  DATA_FULFILL: 'DATA_FULFILL',
  DATA_REQUEST: 'DATA_REQUEST',
  IFRAME_RELOAD: 'IFRAME_RELOAD',
  MODULES_DATA: 'MODULES_DATA',
  NOT_FOUND: 'NOT_FOUND',
  OPEN_HELP_CENTER: 'OPEN_HELP_CENTER',
  PERMISSIONS: 'PERMISSIONS',
  SHELL_REDIRECT: 'SHELL_REDIRECT',
  SHELL_SETTINGS: 'SHELL_SETTINGS',
  SKIP_MENU_GET_LIST: 'SKIP_MENU_GET_LIST',
  SKIP_MENU_SET_LIST: 'SKIP_MENU_SET_LIST'
};

// All internal events and APIs
export const Events = {
  ...EventsThirdParty,
  AEM_INSTANCES: 'AEM_INSTANCES',
  AFTER_PRINT_HANDLER: 'AFTER_PRINT_HANDLER',
  AGREEMENTS: 'AGREEMENTS',
  AI_MESSAGE: 'AI_MESSAGE',
  APP_CACHE: 'APP_CACHE',
  APP_DATA: 'APP_DATA',
  BEFORE_PRINT_HANDLER: 'BEFORE_PRINT_HANDLER',
  CLIPBOARD_WRITE: 'CLIPBOARD_WRITE',
  COMPONENT_CLOSE: 'COMPONENT_CLOSE',
  COMPONENT_OPEN: 'COMPONENT_OPEN',
  CONSENT_GET: 'CONSENT_GET',
  CONSENT_UPDATE: 'CONSENT_UPDATE',
  CUSTOM_HERO_CLICK: 'CUSTOM_HERO_CLICK',
  CUSTOM_LEFT_NAV_CLICK: 'CUSTOM_LEFT_NAV_CLICK',
  CUSTOM_PROFILE_BUTTON_CLICK: 'CUSTOM_PROFILE_BUTTON_CLICK',
  CUSTOM_PROFILE_CLICK: 'CUSTOM_PROFILE_CLICK',
  CUSTOM_TOKEN: 'CUSTOM_TOKEN',
  DATA_PREFETCH: 'DATA_PREFETCH',
  EXC_PULSE: 'EXC_PULSE',
  EXC_SETTINGS: 'EXC_SETTINGS',
  EXTENDED_SHELL_INFO: 'EXTENDED_SHELL_INFO',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  FEEDBACK_CONFIG: 'FEEDBACK_CONFIG',
  FULFILLABLE_ITEMS: 'FULFILLABLE_ITEMS',
  GENERATE_SUBORG: 'GENERATE_SUBORG',
  JIRA_SUBMIT: 'JIRA_SUBMIT',
  NAV_ITEM_CLICK: 'NAV_ITEM_CLICK',
  PRINT_NOW: 'PRINT_NOW',
  PULSE_BUTTON: 'PULSE_BUTTON',
  RUNTIME_REQ: 'RUNTIME_REQ',
  RUNTIME_RESP: 'RUNTIME_RESP',
  SESSION: 'SESSION',
  SPA_SRC_DOC: 'SPA_SRC_DOC',
  TITLE: 'TITLE',
  TRIGGER_NPS: 'TRIGGER_NPS'
};

export interface CustomMouseEvent extends MouseEvent {
  data?: any;
  fromShell?: boolean;
}

export interface CustomKeyboardEvent extends KeyboardEvent {
  data?: any;
  fromShell?: boolean;
}

export interface UserKeyEvent {
  altKey: KeyboardEvent['altKey'];
  bubbles: KeyboardEvent['bubbles'];
  ctrlKey: KeyboardEvent['ctrlKey'];
  data?: any;
  fromShell?: boolean;
  isComposing: KeyboardEvent['isComposing'];
  key: KeyboardEvent['key'];
  keyCode: KeyboardEvent['keyCode'];
  location: KeyboardEvent['location'];
  metaKey: KeyboardEvent['metaKey'];
  repeat: KeyboardEvent['repeat'];
  shiftKey: KeyboardEvent['shiftKey'];
  type: string;
}

export interface UserMouseEvent {
  data?: any;
  type: string;
}
