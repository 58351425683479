/*************************************************************************
 * Copyright 2020 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * APIs that simplify code to make authenticated network requests for resources, execute GraphQL
 * queries, etc..
 *
 * ***Import:***
 *
 * ```typescript
 * import {fetch, query} from '@adobe/exc-app/network';
 * ```
 *
 * ***Default export:***
 *
 * [NetworkApi](../interfaces/network.networkapi.md)
 *
 * ***Usage:***
 *
 * ```typescript
 * import {fetch, query} from '@adobe/exc-app/network';
 *
 * // Performs a window.fetch call with Authorization and x-api-key headers set
 * const fetchResponse = await fetch('https://localhost', {auth: 'Header', method: 'GET'});
 *
 * // Executes a query for resources to the ExC GraphQL service
 * const queryResponse = await query({
 *   data: {
 *     query: `
 *       query PPSQuery($userId: String!, $apiKey: String!) {
 *         getPPSProfile(userId: $userId, apiKey: $apiKey) {
 *           images
 *         }
 *       }`,
 *     variables: {
 *       apiKey: 'test-app',
 *       userId: '123@AdobeID'
 *     }
 *   },
 *   operationName: 'PPSAvatar'
 * });
 *
 * ```
 * @packageDocumentation
 * @module network
 */

import type {APIMode} from './RuntimeConfiguration';
import type {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCacheConfig,
  NormalizedCacheObject
} from '@apollo/client';
import {CacheEntry} from './cache';
import {getImpl} from './src/Global';
import type {gql} from 'graphql-tag';
import type {Sandbox} from './user';

/**
 * Default status codes which imply a transient error and can be retried.
 */
export const DEFAULT_STATUS_CODES_TO_RETRY = [429, 502, 503, 504];
export type GraphQLRegion = 'va7' | 'aus5' | 'nld2' | 'va6';

export interface FetchOptions {
  /**
   * A boolean value indicating whether to add Authentication token, API Key to the request.
   */
  auth?: 'Header' | 'Body';
  /**
   * Number indicating how many fetch attempts should be made using exponential backoff.
   */
  maxRetries?: number;
  metadata?: DefaultMetaData;
  /**
   * Add a unique request UUID (x-request-id header)
   * Set this to the request ID to be used, or 'auto' for generating one automatically.
   */
  requestId?: string;
  /**
   * HTTP Status Codes which will prompt a retry. If not provided only network failures
   * will prompt a retry.
   */
  statusCodesToRetry?: number[];
  /**
   * Specify the headers to be added to the request
   */
  scope?: FetchScope
  /**
   * Number in ms indicating the maximum amount of time taken by the the fetch request plus any
   * exponential backoff retry requests before aborting.
   */
  totalFetchTime?: number;
}

/**
 * @ignore
 */
export interface Configuration {
  /**
   * Unified Shell API Gateway URL
   */
  apiGatewayUrl: string;
  /**
   * API Key that will be added to x-api-key on requests
   */
  apiKey: string;
  /**
   * appId from solution route config
   */
  appId: string;
  /**
   * Should API calls go directly to IO or accelerated via AFD/AWS (Default 'afd')
   */
  apiMode?: APIMode;
  /**
   * IMS Client ID
   */
  imsClientId?: string;
  /**
   * IMS Token
   */
  imsToken: string;
  /**
   * Is the org an AWS org
   */
  isAWSOrg?: boolean;
  /**
   * metricsAppId from solution route config
   */
  metricsAppId?: string;
  /**
   * Ims Org Region
   */
  orgRegion?: string;
  /**
   * @deprecated tenantAppId from solution route config
   */
  tenantAppId?: string;
  /**
   * IMS org id
   */
  imsOrg?: string;
  /**
   * Current sandbox object
   */
  sandbox?: Sandbox;
  /**
   * Adobe IO gateway URL used as a fallback in GQL call
   */
  ioGatewayUrl?: string;
  /**
   * Map of Adobe IO region specific endpoints.
   */
  ioRegionSpecificMap?: Record<string, string>;
  /**
   * Clients can pass in their local GQL endpoint
   */
  gqlEndpoint?: string;
  /**
   * XQL gateway used for AEP specific queries
   */
  xqlGatewayUrl?: string;
}

/**
 * GQL Query containing graphql query and variables.
 */
export interface GraphQLQuery {
  /**
   * GQL Query.
   *
   * ***Example:***
   *
   * ```typescript
   * {query: `
   *   query PPSQuery($userId: String!, $apiKey: String!) {
   *     getPPSProfile(userId: $userId, apiKey: $apiKey) {
   *       images
   *     }
   *   }`
   * };
   *
   * {query: `
   *   query PPSQuery {
   *     getPPSProfile(userId: "123@AdobeID", apiKey: "test-app") {
   *       images
   *     }
   *   }`
   * };
   * ```
   */
  query: string;
  /**
   * Query specific variables- key value pairs.
   *
   * ***Example:***
   *
   * ```typescript
   * {variables: {
   *   apiKey: 'test-app',
   *   userId: '123@AdobeID'
   *  }
   * };
   * ```
   */
  variables?: Record<string, any>;
}

export interface ExtendedGraphQLQuery extends GraphQLQuery {
  extensions?: {
    clientContext?: {
      applicationId?: string;
      deviceId?: string;
      groupId?: string;
      historyId?: string;
      instanceId?: string;
      sessionId?: string;
      userId?: string;
      windowId?: string;
    }
  }
}

/**
 * SPA's can pass in the metadata to override the default values
 */
export interface DefaultMetaData {
  apiKey?: string;
  /**
   * @deprecated This has been replaced with apiKey as the preferred property,
   * as both control the x-api-key header.
   */
  imsClientId? : string;
  token? : string;
  imsOrg? : string;
  sandbox?: Record<string, any>;
}

export type PrefetchResponse<T> = CacheEntry<T> & {
  getFresh?: () => Promise<PrefetchResponse<T>>;
};

/**
 * Data prefetch options
 */
export interface PrefetchOptions {
  /**
   * Must return recent data - Created after provided time
   */
  createdAfter?: number;
  /**
   * If returning cached result, revalidate immediatelly.
   */
  revalidateImmediately?: boolean;
}

export enum ROUTING {
  /**
   * Route by the AEP region found in the user profile
   */
  AEP_PROFILE_BASED,
  /**
   * Default routing - Latency based.
   */
  DEFAULT,
  /**
   * Specify region in query (Client Side routing)
   */
  REGION_BASED_PER_QUERY
}

/**
 * Query request interface.
 */
export interface QueryRequest {
  /**
   * Set the Accept-Language HTTP header in the request. Defaults to '*' (any language).
   *
   * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language for more details.
   *
   */
  acceptLanguage?: string;
  /**
   * Overwrite config.appId until federation is out. This allow scenario where sharing components that are linked to their own tenants.
   */
  appId?: string;
  /**
   * Data containing single or multiple GQL queries.
   *
   * ***Example:***
   *
   * ```typescript
   * {data: {
   *   query: `
   *     query PPSQuery($userId: String!, $apiKey: String!) {
   *       getPPSProfile(userId: $userId, apiKey: $apiKey) {
   *         images
   *       }
   *     }`,
   *   variables: {
   *     userId: '123@AdobeID',
   *     apiKey: 'test-app'
   *   }
   *  }
   * };
   * ```
   */
  data: GraphQLQuery | Array<GraphQLQuery>;
  /**
   * Use for external GraphQL endpoint only.
   */
  endpoint?: string;
  /**
   * GQL Extensions.
   */
  extensions?: Record<string, any>;
  /**
   * Number indicating how many fetch attempts should be made using exponential backoff.
   */
  maxRetries?: number;
  /**
   * default metadata should be given when you want to override the default metadata which will be passed with every request
   */
  metadata?: DefaultMetaData;
  /**
   * passed as true if someone wants to call the region specific endpoints directly.
   */
  /**
   * Query ID- To analyze a query's metrics & performance.
   *
   * ***Example:***
   *
   * ```typescript
   * {operationName: 'PPSAvatar'}
   * ```
   */
  operationName?: string;
  regionEnabled?: boolean;
  /**
   * Enable profile based routing
   */
  routing?: ROUTING;
  /**
   * Pass enum to set headers according to the requirements
   */
  scope?: FetchScope;
  /**
   * HTTP Status Codes which will prompt a retry. If not provided only network failures
   * will prompt a retry.
   */
  statusCodesToRetry?: number[];
  /**
   * Number in ms indicating the maximum amount of time taken by the request plus any exponential backoff
   * requests before aborting.
   */
  totalFetchTime?: number;
  /**
   * Preferred region to send the GraphQL request to
   */
  preferredRegion?: GraphQLRegion;
}

/**
 * This parameter will be used to specify what headers are automatically added to the API call.
 */
export enum FetchScope {
  /**
   * ***none: Minimal scope***
   * No headers are added. Use case: Non authenticated calls.
   */
  NONE = 'NONE',
  /**
   * *** auth: Authentication only. Only auth headers will be added: ***
   * authentication: Bearer <TOKEN>
   * x-api-key: exc_app or custom ID provided by SPA configuration
   */
  AUTH = 'AUTH',
  /**
   * *** org: Authentication + IMS Org. ***
   * authentication: Bearer <TOKEN>
   * x-api-key: exc_app or custom ID provided by SPA configuration
   * x-gw-ims-org-id: <IMS ORG ID>
   */
  ORG = 'ORG',
  /**
   * *** sandbox: Authentication +IMS Org + PALM Sandboxes. ***
   * authentication: Bearer <TOKEN>
   * x-api-key: exc_app or custom ID provided by SPA configuration
   * x-gw-ims-org-id: <IMS ORG ID>
   * x-sandbox-name: <SANDBOX NAME>
   */
  SANDBOX = 'SANDBOX',
  /**
   * *** sandbox-plus: additional headers required with sandbox . ***
   * authentication: Bearer <TOKEN>
   * x-api-key: exc_app or custom ID provided by SPA configuration
   * x-gw-ims-org-id: <IMS ORG ID>
   * x-sandbox-name: <SANDBOX NAME>
   * x-sandbox-type: <SANDBOX TYPE>
   * x-sandbox-default: <SANDBOX DEFAULT>
   */
  SANDBOX_PLUS = 'SANDBOX_PLUS'
}

/**
 * Defines the object containing any custom settings that you want to apply to the request. You can
 * also additionally specify the 'auth' parameter to automatically set the Authentication, API key in Headers/Query params.
 *
 * ***Example:***
 *
 * `{auth: 'Header', body: 'xyz', headers: {'Content-Type': 'text/plain'}, method: 'POST'}` or
 * `{auth: 'Header', method: 'GET'}` or
 * `{method: 'GET'}`
 */
export type FetchInit = RequestInit & FetchOptions;

export interface ApolloClientLegacyOptions {
  cacheOptions?: InMemoryCacheConfig;
  connectToDevTools?: boolean;
  endpoint?: string;
  inputApolloLink?: ApolloLink;
  routing?: ROUTING;
  xql?: boolean;
}

export interface CreateApolloClientOptions<TCacheShape> extends ApolloClientOptions<TCacheShape> {
  endpoint?: string;
  routing?: ROUTING;
  xql?: boolean;
}

export interface ApolloClientV3Classes {
  ApolloClient: typeof ApolloClient;
  ApolloLink: typeof ApolloLink;
  HttpLink: typeof HttpLink;
}

export interface NetworkApi {
  /**
   * Provides an interface for fetching resources powered by the global 'fetch' API.
   *
   * ***Example:***
   *
   * ```typescript
   * // performs a window.fetch call
   * let response = await fetch('https://example.com/api/ping');
   *
   * // performs a window.fetch call with Authorization and x-api-key headers set
   * response = await fetch('https://localhost', {auth: 'Header', method: 'GET'});
   *
   * // performs a window.fetch call with user_token and client_id query parameters added to the URL
   * const request = new Request('https://localhost', {
   *   body: JSON.stringify({k: 'v'}),
   *   headers: new Headers(),
   *   method: 'POST'
   * });
   * response = await fetch(request, {auth: 'Header'});
   * ```
   * @param input The resource that you wish to fetch. It can either be the URL of the resource
   * you want to fetch or a Request object.
   * @param init An object containing any custom settings that you want to apply to the request.
   * @returns The promise for the response to the fetch operation.
   */
  fetch(input: RequestInfo, init?: FetchInit): Promise<Response>;

  /**
   * Provides an interface for querying known data.
   * Data querying and caching are managed in Unified Shell in advance.
   *
   * This is an experimental feature.
   * @template T
   * @param key - Data Contract key
   * @param options - Prefetch options
   * @returns Promise for the contract execution response
   */
  getPrefetched<T>(key: string, options?: PrefetchOptions): Promise<PrefetchResponse<T>>;

  /**
   * Provides an interface for querying resources via GraphqQL.
   * In order to consume query, please make sure the respective query resolver is
   * available in the GraphQL Service.
   *
   * ***Example:***
   *
   * ```typescript
   * const PPS_QUERY = `
   *   query PPSQuery($userId: String!, $apiKey: String!) {
   *     getPPSProfile(userId: $userId, apiKey: $apiKey) {
   *       images
   *     }
   *   }`;
   *
   * // queries the respective resource via GraphQL and returns HTTP Response {ok: true, status: 200, ...}
   * query({data: {query: PPS_QUERY, variables: {
   *   userId: '123@AdobeID',
   *   apiKey: 'test-app',
   * }}, operationName: 'PPSAvatar'});
   *
   * // queries the respective resource via GraphQL and returns HTTP Response {ok: true, status: 200, ...}
   * query({data: {query: `
   *   query PPSQuery {
   *     getPPSProfile(userId: "123@AdobeID", apiKey: "test-app") {
   *       images
   *     }
   *   }`
   * }});
   * ```
   * @param request Query request containing desired GQL Query.
   * @returns The promise for the response to the query operation.
   */
  query(request: QueryRequest): Promise<Response>;

  /**
   * Provides an interface for querying resources via GraphqQL using ApolloClient
   * ***Example***
   * ```typescript
   *  const apolloClientModule = await getApolloClient();
   *  const apolloClient = apolloClientModule.apolloClient;
   *  const gql = apolloClientModule.gql;
   *  const result = await apolloClient.query({
   *    query: gql`query  user {
   *      id
   *      name
   *    }`,
   *    variables : {}
   *  });
   *  console.log(result.data);
   * ```
   * @deprecated use createApolloClient instead.
   *
   * @param options Configuration to create ApolloClient instance
   *
   * @returns GraphQL query response
   *
   */
  getApolloClient(options?:ApolloClientLegacyOptions): Promise<{
    apolloClient: ApolloClient<NormalizedCacheObject>;
    gql: typeof gql;
  }>;

  createApolloClient<TCacheShape>(
    apolloClientProvided: ApolloClientV3Classes,
    options: CreateApolloClientOptions<TCacheShape>
  ): Promise<ApolloClient<TCacheShape>>;
}

/**
 * Provides an interface for fetching resources powered by the global 'fetch' API.
 *
 * ***Example:***
 *
 * ```typescript
 * // performs a window.fetch call
 * let response = await fetch('https://example.com/api/ping');
 *
 * // performs a window.fetch call with Authorization and x-api-key headers set
 * response = await fetch('https://localhost', {auth: 'Header', method: 'GET'});
 *
 * // performs a window.fetch call with user_token and client_id query parameters added to the URL
 * const request = new Request('https://localhost', {
 *   body: JSON.stringify({k: 'v'}),
 *   headers: new Headers(),
 *   method: 'POST'
 * });
 * response = await fetch(request, {auth: 'Header'});
 * ```
 * @param input The resource that you wish to fetch. It can either be the URL of the resource you
 * want to fetch or a Request object.
 * @param init An object containing any custom settings that you want to apply to the request.
 * @returns The promise for the response to the fetch operation.
 */
export function fetch(input: RequestInfo, init?: FetchInit): Promise<Response> {
  return getImpl('network').fetch(input, init);
}

/**
 * Provides an interface for querying known data.
 * Data querying and caching are managed in Unified Shell in advance.
 *
 * This is an experimental feature.
 * @template T
 * @param key - Data Contract key
 * @param options - Prefetch options
 * @returns Promise for the contract execution response
 */
export function getPrefetched<T>(key: string, options?: PrefetchOptions): Promise<PrefetchResponse<T>> {
  return getImpl('network').getPrefetched(key, options);
}

/**
 * Provides an interface for querying resources via GraphqQL.
 * In order to consume query, please make sure the respective query resolver is
 * available in the GraphQL Service.
 * ***Example:***
 *
 * ```typescript
 * const PPS_QUERY = `
 *   query PPSQuery($userId: String!, $apiKey: String!) {
 *     getPPSProfile(userId: $userId, apiKey: $apiKey) {
 *       images
 *     }
 *   }`;
 *
 * // queries the respective resource via GraphQL and returns HTTP Response {ok: true, status: 200, ...}
 * query({data: {query: PPS_QUERY, variables: {
 *   userId: '123@AdobeID',
 *   apiKey: 'test-app',
 * }}, operationName: 'PPSAvatar'});
 *
 * // queries the respective resource via GraphQL and returns HTTP Response {ok: true, status: 200, ...}
 * query({data: {query: `
 *   query PPSQuery {
 *     getPPSProfile(userId: "123@AdobeID", apiKey: "test-app") {
 *       images
 *     }
 *   }`
 * }});
 * ```
 * @param input Query request containing desired GQL Query.
 * @returns The promise for the response to the query operation.
 */
export function query(input: QueryRequest): Promise<Response> {
  return getImpl('network').query(input);
}

/**
 * Provides an interface for querying resources via GraphqQL using ApolloClient
 * ***Example***
 * ```typescript
 *  const apolloClientModule = await getApolloClient();
 *  const apolloClient = apolloClientModule.apolloClient;
 *  const gql = apolloClientModule.gql;
 *  const result = await apolloClient.query({
 *    query: gql`query  user {
 *      id
 *      name
 *    }`,
 *    variables : {}
 *  });
 *  console.log(result.data);
 * ```
 * @param options Configuration to create ApolloClient instance
 * @returns GraphQL query response
 *
 */
export function getApolloClient(
  options?: ApolloClientLegacyOptions
): Promise<{
    apolloClient: ApolloClient<NormalizedCacheObject>;
    gql: typeof gql;
  }> {
  return getImpl('network').getApolloClient(options);
}

export function createApolloClient<TCacheShape>(
  apolloClientProvided: ApolloClientV3Classes,
  options: CreateApolloClientOptions<TCacheShape>
): Promise<ApolloClient<TCacheShape>> {
  return getImpl('network').createApolloClient(apolloClientProvided, options);
}
