/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CallbackObject, RuntimeMessenger} from '../models/runtimeModels';
import type {ConfigProxyManager} from '../config';
import {Events} from '@exc/shared';
import type {
  FeedbackProgram,
  HelpCenterApi,
  HelpCenterButtonConfig,
  HelpCenterOpenConfig,
  JiraFeedback
} from '@adobe/exc-app/helpcenter';
import type {MetricsApi} from '@adobe/exc-app/metrics';

const getButtons = (buttonConfig: HelpCenterButtonConfig | HelpCenterButtonConfig[] | null): HelpCenterButtonConfig[] => {
  if (!buttonConfig) {
    return [];
  }
  return Array.isArray(buttonConfig) ? buttonConfig : [buttonConfig];
};

export default (messenger: RuntimeMessenger, configManager: ConfigProxyManager, metricsApi: MetricsApi) => {
  const metrics = metricsApi.create('exc.module-runtime.helpCenter');
  const scopes = ['helpCenter', 'helpCenterResource'];

  const defaultOpenConfig: HelpCenterOpenConfig = {
    config: {
      subject: 'subject',
      type: 'CONTEXTUAL_FEEDBACK_SUBMISSION'
    },
    selectedTab: 'feedback'
  };

  const helpCenterInstance = {
    close: () => messenger.send(Events.OPEN_HELP_CENTER),
    open: (openConfig = defaultOpenConfig) => messenger.send(Events.OPEN_HELP_CENTER, openConfig),
    setButton: (buttonConfig: HelpCenterButtonConfig | HelpCenterButtonConfig[] | null) => {
      let customButtons = configManager.get('customButtons') || [];
      const buttons = getButtons(buttonConfig);
      // Reset customButtons
      if (!buttonConfig || buttons.length === 0) {
        customButtons = customButtons.filter(button => scopes.indexOf(button.scope) === -1);
        configManager.set({customButtons});
        messenger.setCallback(Events.CUSTOM_HELP_BUTTON_CLICK, null);
        return;
      }
      // Add buttons
      const callbacks: CallbackObject = {};
      buttons.forEach(({
        callback,
        id = 'create_analytics_support_ticket',
        label,
        scope
      }: HelpCenterButtonConfig) => {
        customButtons.unshift({
          id,
          label,
          scope: scope || 'helpCenter'
        });
        callback && (callbacks[id] = callback);
      });
      configManager.set({customButtons});
      messenger.setCallback(Events.CUSTOM_HELP_BUTTON_CLICK, callbacks);
    },
    setButtons: (buttons: HelpCenterButtonConfig[] | null) => helpCenterInstance.setButton(buttons),
    setFeedbackConfig: (programs: FeedbackProgram | FeedbackProgram[]) => {
      const programArray = Array.isArray(programs) ? programs : [programs];
      const programNames = new Set;
      programArray.forEach(({name = 'unknown'}) => programNames.add(name));

      // Call out if a program configuration is set up with duplicate names so
      // the developer can be warned.
      if (programArray.length > programNames.size) {
        metrics.warn('Using duplicate beta program names makes it harder to uniquely identify feedback.', {programs});
      }
      messenger.send(Events.FEEDBACK_CONFIG, programArray);
    },
    submitJiraFeedback: (feedbackConfig: JiraFeedback) => messenger.send(Events.JIRA_SUBMIT, feedbackConfig)
  } as HelpCenterApi;

  Object.defineProperty(helpCenterInstance, 'config', {
    get: () => configManager.get('helpCenter'),
    set: helpCenterConfig => {
      configManager.set({helpCenter: helpCenterConfig});
    }
  });

  return helpCenterInstance;
};
