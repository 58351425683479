/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  BlockNavigationOptions,
  LocationLike,
  PageApi,
  PerformanceRecord,
  ShellRedirectOptions
} from '@adobe/exc-app/page';
import type {ConfigProxyManager} from '../config';
import type {ConfigureListener, RuntimeMessenger} from '../models/runtimeModels';
import {done} from '../runtimeDeferred';
import {Events} from '@exc/shared';
import {generateShellUrl} from '../util';
import type {MetricsApi} from '@adobe/exc-app/metrics';
import {ObservableType} from '@adobe/exc-app/page';
import type {ObservedConfig, ObserverClass} from '../models/observer';
import Observer from './observer';
import type {RuntimeConfiguration} from '@adobe/exc-app';

// This is the default popover selector that should be connected to the modal
// list of selectors because it was there in the previous implementation. This
// must be separated because it was always included and could not be overridden.
const DEFAULT_POPOVER_SELECTOR = '[data-testid="popover"]';

export const OBSERVER_DEFAULT_SELECTORS: Record<ObservableType, string[]> = {
  [ObservableType.MODAL]: ['[role="dialog"]', '[role="alertdialog"]', DEFAULT_POPOVER_SELECTOR],
  [ObservableType.POPOVER]: ['[class*="spectrum-Popover"]']
};

export function addShellPlaceholder(document: Document, runtimeMessenger: RuntimeMessenger): void {
  if (document.getElementById('shell-placeholder')) {
    return;
  }
  const div = document.createElement('div');
  div.id = 'shell-placeholder';
  document.body.insertBefore(div, document.body.childNodes[0]);
  runtimeMessenger.send(Events.CAN_TAKEOVER, {});
}

export interface PageObject extends ConfigureListener {
  addShellPlaceholder: () => void;
  OBSERVED_MAP: Record<ObservableType, Partial<ObservedConfig>>;
  page: PageApi;
}

export default (
  messenger: RuntimeMessenger,
  configManager: ConfigProxyManager,
  metricsApi: MetricsApi,
  window: Window & typeof globalThis
): PageObject => {
  const {document, performance} = window;
  let configuration: RuntimeConfiguration;
  const metrics = metricsApi.create('exc.module-runtime.page');
  let observer: ObserverClass;

  // Initialize the observable selectors, which can be overridden by developers.
  const observerSelectors: Record<ObservableType, string[]> = {
    [ObservableType.MODAL]: [...OBSERVER_DEFAULT_SELECTORS[ObservableType.MODAL]],
    [ObservableType.POPOVER]: [...OBSERVER_DEFAULT_SELECTORS[ObservableType.POPOVER]]
  };

  const pageInstance = {
    afterPrintHandler: (callback: (ev: Event) => void): void => {
      messenger.send(Events.AFTER_PRINT_HANDLER);
      messenger.setCallback(Events.AFTER_PRINT_HANDLER, callback);
    },
    beforePrintHandler: (callback: (ev: Event) => void): void => {
      messenger.send(Events.BEFORE_PRINT_HANDLER);
      messenger.setCallback(Events.BEFORE_PRINT_HANDLER, callback);
    },
    blockNavigation: (enabled: boolean, options?: BlockNavigationOptions): void => {
      messenger.send(Events.BLOCK_NAVIGATION, {enabled, options});
    },
    clipboardWrite: (msg: string): void => {
      messenger.send(Events.CLIPBOARD_WRITE, {msg});
    },
    done: (): Promise<PerformanceRecord> => done(performance.now(), messenger, metricsApi, window),
    generateShellUrl: (location: LocationLike, newApp?: boolean) => generateShellUrl(configuration, location, newApp),
    getModalQuerySelectors: (): Array<string> => pageInstance.getObservableQuerySelectors(ObservableType.MODAL),
    getObservableQuerySelectors: (type: ObservableType): string[] => observerSelectors[type],
    iframeReload: (cacheBust?: boolean): void => {
      messenger.send(Events.IFRAME_RELOAD, {cacheBust: cacheBust === undefined ? !configuration.spaAppId : cacheBust});
    },
    notFound: (): void => {
      messenger.send(Events.NOT_FOUND, {});
    },
    openInNewTab: (path: string, newApp?: boolean) => {
      window.open(pageInstance.generateShellUrl({path}, newApp));
    },
    print: (callback: () => void): void => {
      messenger.setCallback(Events.PRINT_NOW, callback);
      messenger.send(Events.PRINT_NOW);
    },
    reloadSandbox: (): void => {
      messenger.send(Events.DEPRECATION_WARNING, 'reloadSandbox');
    },
    setModalQuerySelectors: (selectors?: string[]): void => {
      metrics.event('OverlayAction', {setModalQuerySelectors: {selectors}});
      pageInstance.setObservableQuerySelectors(ObservableType.MODAL, selectors);
    },
    setObservableQuerySelectors: (type: ObservableType, selectors?: string[], forceCustomOnly?: boolean): void => {
      metrics.event('OverlayAction', {setObservableQuerySelectors: {selectors, type}});
      observerSelectors[type] = selectors?.length ? selectors : OBSERVER_DEFAULT_SELECTORS[type];
      // Current functionality includes a default popover selector with modals.
      // Adding the `forceCustomOnly` param will remove it.
      if (!forceCustomOnly && type === ObservableType.MODAL && observerSelectors[type].indexOf(DEFAULT_POPOVER_SELECTOR) === -1) {
        observerSelectors[type].push(DEFAULT_POPOVER_SELECTOR);
      }
    },
    shellRedirect: (pathOrUrl, options?: ShellRedirectOptions) => {
      const property = pathOrUrl.startsWith('https:') ? 'url' : 'path';
      messenger.send(Events.SHELL_REDIRECT, {...options, [property]: pathOrUrl});
    },
    toggleAutoDetect: async (type: ObservableType, enable: boolean) => {
      if (enable) {
        observer || (observer = new Observer(document.body, window));
        observer.observe({...OBSERVED_MAP[type], id: type} as ObservedConfig);
      } else {
        observer.unobserve(type);
      }
      metrics.event('OverlayAction', {toggleAutoDetect: {enable, type}});
    }
  } as PageApi;

  // Map of observed properties to their configs that the observer will use.
  const OBSERVED_MAP: Record<ObservableType, Partial<ObservedConfig>> = {
    [ObservableType.MODAL]: {
      callback: (added: boolean) => {
        const selectors = pageInstance.getObservableQuerySelectors(ObservableType.MODAL).join(',');
        if (added || !document.querySelectorAll(selectors).length) {
          pageInstance.modal = added;
        }
      },
      getSelectors: () => pageInstance.getObservableQuerySelectors(ObservableType.MODAL)
    },
    [ObservableType.POPOVER]: {
      // eslint-disable-next-line no-restricted-globals
      callback: (added: boolean, el?: Element) => setTimeout(() => {
        const element = el as HTMLElement;

        // If the popover was removed or the element does not exist, there is
        // nothing more to do.
        if (!added || !element) {
          return;
        }
        const {shellHeight} = configuration;
        const minTopPosition = shellHeight + 10;

        // Only resize if the element is underneath the Shell header. Adjust the
        // position check for window scroll to ensure that the popover is always
        // correctly resized for accessibility purposes.
        if (element.offsetTop > (minTopPosition + window.scrollY)) {
          return;
        }
        const newHeight = element.offsetHeight - (minTopPosition - element.offsetTop + window.scrollY);
        element.style.height = `${newHeight}px`;
      }, 20),
      getSelectors: () => pageInstance.getObservableQuerySelectors(ObservableType.POPOVER)
    }
  };

  configManager.setupConfig(
    pageInstance,
    [
      'appContainer',
      'favicon',
      'modal',
      'modalAutoDetect',
      'preventDefaultCombos',
      'spinner',
      'title',
      'unloadPromptMessage'
    ],
    (apiName, value) => {
      // If appContainer is set, add the placeholder element to the DOM. This
      // will be how the application maintains the existing UI when appContainer
      // is enabled.
      if (value && apiName === 'appContainer' && !configuration?.inModal) {
        addShellPlaceholder(document, messenger);
      }

      // Legacy usage for modalAutoDetect. Uses the new toggleAutoDetect API to
      // toggle the observer on/off depending on `value`.
      if (apiName === 'modalAutoDetect') {
        metrics.event('OverlayAction', {modalAutoDetect: {value}});
        pageInstance.toggleAutoDetect(ObservableType.MODAL, value);
      }
    });

  return {
    addShellPlaceholder: () => addShellPlaceholder(document, messenger),
    OBSERVED_MAP,
    onConfigure: (config: RuntimeConfiguration): void => {
      configuration = config;
    },
    page: pageInstance
  };
};
