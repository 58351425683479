/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {APIMode} from '@adobe/exc-app/RuntimeConfiguration';
import {
  Configuration,
  DEFAULT_STATUS_CODES_TO_RETRY,
  ExtendedGraphQLQuery,
  FetchScope,
  QueryRequest,
  ROUTING
} from '@adobe/exc-app/network';
import type {ExtendedFetch} from './fetch';
import {getOperationName} from '@exc/shared';
import {Level, Metrics, MetricsApi} from '@adobe/exc-app/metrics';
import uuidv1 from 'uuid/v1';

const XQL_APPID = 'xql';
const NA = 'unknown';

export type BaseQueryFn = (input: QueryRequest, body?: string, client?: string) => Promise<Response>;

interface GraphQLConfig {
  apiGatewayUrl: string;
  apiMode: APIMode;
  appId: string;
  gqlEndpoint: string;
  ioGatewayUrl: string;
  ioRegionSpecificMap: Record<string, string>;
  isAWSOrg: boolean;
  xql: string;
}

function logGqlError(logger: Metrics, error: string, input: QueryRequest, requestId: string = NA, requestNoAuth: string = NA): void {
  const {appId, operationName, routing} = input;
  logger.event('GraphQL.Error', error, {
    appId,
    error,
    operationName,
    requestId,
    requestNoAuth,
    routing
  }, {level: Level.ERROR});
}

const logInvalidScopeForGraphqlProvided = (logger: Metrics, input: QueryRequest) => {
  if (input.scope === FetchScope.NONE) {
    logGqlError(logger, 'FetchScope type None used in graphql query', input);
    throw new Error(`FetchScope type None used in graphql query ${input.operationName}`);
  }
};

const normalizeQueryInput = (graphQLConfig: GraphQLConfig, input: QueryRequest): QueryRequest => ({
  ...input,
  appId: input.appId || graphQLConfig.appId,
  operationName: input.operationName || (!(input.data instanceof Array) ? getOperationName(input.data?.query) : NA)
});

const getClientContext = (win: Window & typeof globalThis) => {
  if (win.adobeMetrics) {
    const {adobeMetrics: {
      metricsState: {
        application: {id: applicationId, version} = {id: 'unknown', version: 'unknown'},
        deviceId, instanceId, windowId
      } = {},
      pageState: {historyId} = {},
      user: {groupId, sessionId} = {}}
    } = win;
    return {
      applicationId: `${applicationId}:${version}`,
      deviceId,
      groupId,
      historyId,
      instanceId,
      sessionId,
      windowId
    };
  }
};

const getGraphQLEndpoint = (graphQLConfig: GraphQLConfig, input: QueryRequest, primary: boolean, logger: Metrics) => {
  const {apiMode, apiGatewayUrl, gqlEndpoint, ioGatewayUrl, isAWSOrg, xql: xqlEndpoint} = graphQLConfig;
  const {appId, endpoint, operationName, routing} = input;
  const suffix = `/graphql?appId=${appId}`;
  let path = `/api/gql/app/${appId}${suffix}`;

  // There are 3 possible options for using non-federated GraphQL instances.
  // xql - AEP XQL GraphQL instance
  // endpoint - A custom endpoint provided via the API call
  // gqlEndpoint - A custom GraphQL endpoint provided in the SPA config
  // If any of those is set, use it.
  const customEndpoint = appId === XQL_APPID ? xqlEndpoint : endpoint || gqlEndpoint;
  if (customEndpoint) {
    // Custom endpoint, return the custom endpoint.
    return customEndpoint;
  }

  if (isAWSOrg) {
    // AWS call, return the AWS endpoint.
    return `${ioGatewayUrl}/aws${path}`;
  }

  if (apiMode === 'io' || !primary) {
    // return the IO endpoint.
    return `${ioGatewayUrl}${routing === ROUTING.AEP_PROFILE_BASED ? '/profile' : ''}/graph${suffix}`;
  }

  // return the primary endpoint.
  if (routing === ROUTING.REGION_BASED_PER_QUERY) {
    if (input.preferredRegion) {
      path = `/api/gql/region/${input.preferredRegion}/app/${appId}${suffix}`;
    } else {
      logger.warn('REGION_BASED_PER_QUERY routing requested, but region not provided', {operationName});
    }
  }

  if (routing === ROUTING.AEP_PROFILE_BASED) {
    path = `/api/gql/profile/graphql${suffix}`;
  }

  return `${apiGatewayUrl}${path}`;
};

const getRequestOptions = (
  logger: Metrics,
  graphQLConfig: GraphQLConfig,
  input: QueryRequest,
  win: Window & typeof globalThis,
  incomingBody?: string
): {
  url: string;
  init: Record<string, any>;
  ioEndpoint: string|undefined ;
  ioRegionSpecificMap: Record<string, string>|undefined;
} => {
  const {ioRegionSpecificMap, xql} = graphQLConfig;
  const {
    acceptLanguage = '*',
    extensions,
    maxRetries,
    metadata,
    regionEnabled,
    totalFetchTime,
    statusCodesToRetry = DEFAULT_STATUS_CODES_TO_RETRY,
    operationName
  } = input;
  let graphQLQueries = (operationName ? {operationName, ...input.data} : input.data || {}) as ExtendedGraphQLQuery;
  if (incomingBody && typeof incomingBody === 'string') {
    graphQLQueries = {...graphQLQueries, ...JSON.parse(incomingBody)};
  }

  const clientContext = getClientContext(win);
  if (clientContext || extensions) {
    graphQLQueries.extensions = {...graphQLQueries.extensions, ...extensions, clientContext};
  }

  let auth: 'Body' | 'Header' = 'Body';
  let requestId: string | undefined = uuidv1();
  let {scope} = input;

  const url = getGraphQLEndpoint(graphQLConfig, input, true, logger);
  const ioEndpoint = getGraphQLEndpoint(graphQLConfig, input, false, logger);

  if (url === xql) {
    // This logic needs to be removed once XQL has moved to federation (EXC-16583)
    auth = 'Header';
    scope = scope || FetchScope.SANDBOX;
    requestId = undefined; // Remove once XQL updated its CORS configuration.
  }

  logInvalidScopeForGraphqlProvided(logger, input);

  return {
    init: {
      auth,
      body: JSON.stringify(graphQLQueries),
      headers: {
        'Accept-Language': acceptLanguage,
        'Content-Type': 'application/json'
      },
      maxRetries,
      metadata,
      method: 'POST',
      requestId,
      scope,
      statusCodesToRetry,
      totalFetchTime
    },
    ioEndpoint: url !== ioEndpoint ? ioEndpoint : undefined,
    ioRegionSpecificMap: regionEnabled ? ioRegionSpecificMap : undefined,
    url
  };
};

const getRequestId = (res: Response): string => res.headers?.get('x-request-id') || NA;

/**
 * Reduce the data to a boolean map of the data keys and whether they are null or not.
 * @param json - GraphQL response
 */
const getDataStats = (json: Record<string, any>) => {
  if (json.data && typeof json.data === 'object') {
    return Object.keys(json.data).reduce<Record<string, boolean>>((acc, key) => {
      acc[key] = json.data[key] !== null;
      return acc;
    }, {});
  }
  return null;
};

const parseJsonSafe = (text: string): Record<string, any> => {
  try {
    return JSON.parse(text);
  } catch (e) {
    return {valid: false};
  }
};

export default (fetchFn: ExtendedFetch, metricsApi: MetricsApi, win: Window & typeof globalThis) => {
  const logger = metricsApi.create('exc.module-runtime.network.graphql');
  const ioRegionMap: Record<string, string> = {};

  let graphQLConfig: GraphQLConfig = {
    apiGatewayUrl: '',
    apiMode: 'afd',
    appId: '',
    gqlEndpoint: '',
    ioGatewayUrl: '',
    ioRegionSpecificMap: ioRegionMap,
    isAWSOrg: false,
    xql: ''
  };

  const baseQuery = async (input: QueryRequest, body?: string, client = 'query'): Promise<Response> => {
    const start = Date.now();
    const normalizedInput = normalizeQueryInput(graphQLConfig, input);
    const {appId, operationName, routing} = normalizedInput;
    const reqOpts = getRequestOptions(logger, graphQLConfig, normalizedInput, win, body);
    const {requestId} = reqOpts.init;
    const logData = {
      appId,
      client,
      operationName,
      requestId,
      routing
    };
    const timer = logger.start(`GraphQL.Query`, operationName || 'query', {...logData});
    try {
      const response = await fetchFn(reqOpts.url, reqOpts.init, reqOpts.ioEndpoint, reqOpts.ioRegionSpecificMap);
      // Measuring duration here to provide a net duration not including event loop lag.
      const duration = Date.now() - start;
      const {headers, ok, status, statusText} = response;
      logData.requestId = getRequestId(response);
      const requestWithoutAuthorization: undefined | string = headers?.get('has-authorization') || NA;
      response.clone().text().then(responseText => {
        // Attempt to parse the response as JSON, if it fails, log the raw response.
        const json: Record<string, any> = parseJsonSafe(responseText);
        const data = getDataStats(json);
        const valid = ok && data !== null && (json.errors === undefined || json.errors.length === 0);
        timer.time('done', operationName || 'query', {
          ...logData,
          data,
          duration,
          errors: json.errors,
          ok,
          status,
          statusText,
          valid
        }, {level: valid ? Level.INFO : Level.ERROR});
        ok || logGqlError(logger, responseText || 'Unknown error', normalizedInput, requestId, requestWithoutAuthorization);
      });
      return response;
    } catch (err) {
      const duration = Date.now() - start;
      const error = err instanceof Error ? err.message : 'Unknown error';
      timer.time('done', operationName || 'query', {
        ...logData, duration, error, ok: false, status: 0, statusText: 'NETWORK ERROR', valid: false
      }, {level: Level.ERROR});
      logGqlError(logger, error, normalizedInput, requestId);
      throw (err || new Error('Unknown error'));
    }
  };

  return {
    baseQuery,
    configure: ({
      apiGatewayUrl,
      apiMode = 'afd',
      appId,
      gqlEndpoint = '',
      ioGatewayUrl = '',
      ioRegionSpecificMap = {},
      isAWSOrg = false,
      xqlGatewayUrl: xql = ''
    }: Configuration): void => {
      graphQLConfig = {
        apiGatewayUrl, apiMode, appId, gqlEndpoint, ioGatewayUrl, ioRegionSpecificMap, isAWSOrg, xql
      };
    },
    query: (input: QueryRequest): Promise<Response> => baseQuery(input)
  };
};
