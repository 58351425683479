/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {
  Callback,
  CoachMarkConfig,
  CustomFeedbackConfig,
  CustomSearchConfig,
  ExternalFeedbackConfig,
  HelpCenterFeedbackConfig,
  TopbarApi
} from '@adobe/exc-app/topbar';
import type {ConfigProxyManager} from '../config';
import {Events} from '@exc/shared';
import type {RuntimeMessenger} from '../models/runtimeModels';

export default (messenger: RuntimeMessenger, configManager: ConfigProxyManager): TopbarApi => {
  const topbarInstance = {
    onHeroClick: (callback: Callback | null) => {
      messenger.send(Events.CUSTOM_HERO_CLICK);
      messenger.setCallback(Events.HERO_CLICK, callback);
    },
    setCoachMark: (coachMark: CoachMarkConfig | null) => {
      if (!coachMark) {
        configManager.clear('coachMark');
        return;
      }
      configManager.update('coachMark', coachMark, Events.COACH_MARK_POSITIONED);
    },
    setCustomSearch: (customSearch: CustomSearchConfig | null) => {
      if (!customSearch) {
        configManager.clear('customSearch', Events.CUSTOM_SEARCH);
        return;
      }
      configManager.update('customSearch', customSearch, Events.CUSTOM_SEARCH);
    },
    setFeedbackButton: (feedback: CustomFeedbackConfig | HelpCenterFeedbackConfig | ExternalFeedbackConfig | null) => {
      if (!feedback) {
        configManager.clear('feedback', Events.CUSTOM_FEEDBACK);
        return;
      }
      configManager.update('feedback', feedback, Events.CUSTOM_FEEDBACK, feedback.type !== 'custom');
    }
  } as TopbarApi;

  configManager.setupConfig(topbarInstance, [
    'customEnvLabel',
    'solution',
    'workspaces'
  ]);
  return topbarInstance;
};
