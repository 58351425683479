/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ObservableType} from '@adobe/exc-app/page';
import type {ObservedConfig, ObserverClass} from '../models/observer';

export default class Observer implements ObserverClass {
  private observedElement: HTMLElement;
  private observed: {[key in ObservableType]?: ObservedConfig} = {};
  // eslint-disable-next-line no-restricted-globals
  private observer: MutationObserver;

  constructor(body: HTMLElement, window: Window & typeof globalThis) {
    const {MutationObserver} = window;
    this.observedElement = body;

    this.observer = new MutationObserver((mutationsList: MutationRecord[]): void => {
      const processNodes = (node: Node, added: boolean) => {
        if (node.nodeType !== Node.ELEMENT_NODE) {
          return;
        }
        Object.values(this.observed).forEach((observed: ObservedConfig) =>
          this.processNode(node, added, observed)
        );
      };
      mutationsList.forEach((mutation: MutationRecord) => {
        mutation.addedNodes.forEach((node: Node) => processNodes(node, true));
        mutation.removedNodes.forEach((node: Node) => processNodes(node, false));
      });
    });
  }

  public observe(toObserve: ObservedConfig) {
    const {id} = toObserve;
    this.observed[id] = toObserve;
    this.toggleObserver();
  }

  private processNode(node: Node, added: boolean, observed: ObservedConfig) {
    const el = (node as Element);
    const {callback, getSelectors} = observed;

    const selectors = getSelectors().join(',');
    let matchedElement = el.matches(selectors) ? el : null;
    matchedElement = matchedElement || el.querySelector(selectors);

    // If there is no matching element, don't trigger the callback. This will
    // happen on all add or remove nodes that do not match the selector.
    if (!matchedElement) {
      return;
    }

    // Since the generic observer handles N types, it's possible that additions
    // and removals happen at various timings and we need to make sure there
    // was an addition or removal to this id before firing a callback.
    callback(added, added ? matchedElement : undefined);
  }

  private toggleObserver() {
    const enable = Object.keys(this.observed).length > 0;
    enable && this.observer.observe(this.observedElement, {childList: true, subtree: true});
    enable || this.observer.disconnect();
  }

  public unobserve(type: ObservableType) {
    delete this.observed[type];
    this.toggleObserver();
  }
}
